// @flow strict

import React, { useContext } from 'react';
import ColorContext from './TabColorContext';
import { StyledTabBody } from '../../styled-components/Tab/TabBody';
import type { TabBodyProps } from './TabBodyProps';

const TabBody = (props: TabBodyProps) => (
  <StyledTabBody
    $color={useContext(ColorContext)}
    {...props}
  />
);

export default TabBody;
