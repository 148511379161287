// @flow strict

import React, { forwardRef, useCallback, useState } from 'react';
import ModalNodeContext from './CanvasModalNodeContext';
import ModalRefContext from './CanvasModalRefContext';
import { StyledCanvas } from '../../styled-components/Canvas/Canvas';
import type { CanvasProps } from './CanvasProps';

const Canvas = (props: CanvasProps, ref: mixed) => {
  const [modalNode, setModalNode] = useState();
  const modalRef = useCallback(setModalNode, []);

  return (
    <ModalRefContext.Provider value={modalRef}>
      <ModalNodeContext.Provider value={modalNode}>
        <StyledCanvas {...props} ref={ref} />
      </ModalNodeContext.Provider>
    </ModalRefContext.Provider>
  );
};

export default forwardRef(Canvas);
