// @flow strict

import React, { useCallback, useContext, useRef } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import TextButtonColorContext from './TextButtonColorContext';
import TextButtonDensityContext from './TextButtonDensityContext';
import TextButtonSizeContext from './TextButtonSizeContext';
import { StyledTextButton } from '../../styled-components/TextButton/TextButton';
import { textButtonInkRippleDuration } from '../../styled-components/TextButton/variables';
import type { TextButtonProps } from './TextButtonProps';
import getContrastColor from '../Background/getContrastColor';
import InkPressesContext from '../Ink/InkPressesContext';
import useInkPresses from '../Ink/useInkPresses';

const TextButton = (props: TextButtonProps) => {
  const inheritedColor = useContext(TextButtonColorContext);
  const [inkPresses, handleInkPress] = useInkPresses(textButtonInkRippleDuration);
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const ref = useRef();

  const {
    as = 'button',
    color: optionalColor,
    density = 0,
    onClick,
    size = 'medium',
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  const contrastColor = getContrastColor(parentBackgroundColor);
  const color = optionalColor ?? inheritedColor ?? contrastColor;

  const handleClick = useCallback((e: HTMLEvent) => {
    if (onClick) onClick(e);
    if (e.target) e.target.blur();
    handleInkPress(e, ref.current);
  }, [handleInkPress]);

  return (
    <InkPressesContext.Provider value={inkPresses}>
      <TextButtonColorContext.Provider value={color}>
        <TextButtonDensityContext.Provider value={density}>
          <TextButtonSizeContext.Provider value={size}>
            <StyledTextButton
              {...rest}
              ref={ref}
              $color={color}
              $density={density}
              $spaceBottom={spaceBottom}
              $spaceLeft={spaceLeft}
              $spaceRight={spaceRight}
              $spaceTop={spaceTop}
              as={as}
              onClick={handleClick}
            />
          </TextButtonSizeContext.Provider>
        </TextButtonDensityContext.Provider>
      </TextButtonColorContext.Provider>
    </InkPressesContext.Provider>
  );
};

export default TextButton;
