// @flow strict

import React from 'react';
import { StyledSubtitle2 } from '../../styled-components/Subtitle2/Subtitle2';
import type { Subtitle2Props } from './Subtitle2Props';
import withTypography from '../Typography/withTypography';

const Subtitle2 = (props: Subtitle2Props) => (
  <StyledSubtitle2 {...props} />
);

export default withTypography(Subtitle2);
