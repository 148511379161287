// @flow strict

import styled from 'styled-components';

export const RawStyledToolbarBodyPrimary = styled.div`
  padding-bottom: ${props => 8 - 2 * props.$density}px;
  padding-top: ${props => 8 - 2 * props.$density}px;
`;

export const StyledToolbarBodyPrimary = RawStyledToolbarBodyPrimary;
