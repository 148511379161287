// @flow strict

import React, { useContext } from 'react';
import ForegroundColorContext from './ContainedButtonForegroundColorContext';
import { StyledContainedButtonBodyText } from '../../styled-components/ContainedButton/ContainedButtonBodyText';
import type { ContainedButtonBodyTextProps } from './ContainedButtonProps';

const ContainedButtonBodyText = (props: ContainedButtonBodyTextProps) => (
  <StyledContainedButtonBodyText
    $foregroundColor={useContext(ForegroundColorContext)}
    {...props}
  />
);

export default ContainedButtonBodyText;
