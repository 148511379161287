// @flow strict

import React, { Fragment, lazy, Suspense } from 'react';

const DeferredSignInDialog = lazy(() => import('./SignInDialog'));

const AsyncSignInDialog = (props: DialogProps) => (
  <Suspense fallback={Fragment}>
    <DeferredSignInDialog {...props} />
  </Suspense>
);

export default AsyncSignInDialog;
