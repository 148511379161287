// @flow strict

import { createGlobalStyle } from 'styled-components';

const StyledCodeMirrorReset = createGlobalStyle`
  .CodeMirror {
    color: black;
    direction: ltr;
    height: 300px;
  }

  .CodeMirror-lines {
    padding: 4px 0;
  }

  .CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
    padding: 0 4px;
  }

  .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    background-color: #fff;
  }

  .CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
  }

  .CodeMirror-linenumbers {}
  .CodeMirror-linenumber {
    color: #999;
    min-width: 20px;
    padding: 0 3px 0 5px;
    text-align: right;
    white-space: nowrap;
  }

  .CodeMirror-guttermarker { color: black; }
  .CodeMirror-guttermarker-subtle { color: #999; }

  .CodeMirror-cursor {
    border-left: 1px solid black;
    border-right: none;
    width: 0;
  }

  .CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver;
  }

  .cm-fat-cursor .CodeMirror-cursor {
    background: #7e7;
    border: 0 !important;
    width: auto;
  }

  .cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1;
  }

  .cm-fat-cursor-mark {
    animation: blink 1.06s steps(1) infinite;
    background-color: rgba(20, 255, 20, 0.5);
  }

  .cm-animate-fat-cursor {
    animation: blink 1.06s steps(1) infinite;
    background-color: #7e7;
    border: 0;
    width: auto;
  }

  @keyframes blink {
    0% {}
    50% { background-color: transparent; }
    100% {}
  }

  .CodeMirror-overwrite .CodeMirror-cursor {}

  .cm-tab { display: inline-block; text-decoration: inherit; }

  .CodeMirror-rulers {
    left: 0; right: 0; top: -50px; bottom: 0;
    overflow: hidden;
    position: absolute;
  }

  .CodeMirror-ruler {
    border-left: 1px solid #ccc;
    top: 0; bottom: 0;
    position: absolute;
  }

  .cm-s-default .cm-header {color: blue;}
  .cm-s-default .cm-quote {color: #090;}
  .cm-negative {color: #d44;}
  .cm-positive {color: #292;}
  .cm-header, .cm-strong {font-weight: bold;}
  .cm-em {font-style: italic;}
  .cm-link {text-decoration: underline;}
  .cm-strikethrough {text-decoration: line-through;}
  .cm-s-default .cm-keyword {color: #708;}
  .cm-s-default .cm-atom {color: #219;}
  .cm-s-default .cm-number {color: #164;}
  .cm-s-default .cm-def {color: #00f;}
  .cm-s-default .cm-variable,
  .cm-s-default .cm-punctuation,
  .cm-s-default .cm-property,
  .cm-s-default .cm-operator {}
  .cm-s-default .cm-variable-2 {color: #05a;}
  .cm-s-default .cm-variable-3, .cm-s-default .cm-type {color: #085;}
  .cm-s-default .cm-comment {color: #a50;}
  .cm-s-default .cm-string {color: #a11;}
  .cm-s-default .cm-string-2 {color: #f50;}
  .cm-s-default .cm-meta {color: #555;}
  .cm-s-default .cm-qualifier {color: #555;}
  .cm-s-default .cm-builtin {color: #30a;}
  .cm-s-default .cm-bracket {color: #997;}
  .cm-s-default .cm-tag {color: #170;}
  .cm-s-default .cm-attribute {color: #00c;}
  .cm-s-default .cm-hr {color: #999;}
  .cm-s-default .cm-link {color: #00c;}
  .cm-s-default .cm-error {color: #f00;}
  .cm-invalidchar {color: #f00;}
  .CodeMirror-composing { border-bottom: 2px solid; }
  div.CodeMirror span.CodeMirror-matchingbracket {color: #0b0;}
  div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #a22;}
  .CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }
  .CodeMirror-activeline-background {background: #e8f2ff;}

  .CodeMirror {
    background: white;
    overflow: hidden;
    position: relative;
  }

  .CodeMirror-scroll {
    /* overflow: scroll !important; /* Things will break if this is overridden */
    overflow: auto !important; /* Let's see if things break… */
    /* 30px is the magic margin used to hide the element's real scrollbars */
    /* See overflow: hidden in .CodeMirror */
    height: 100%;
    margin-bottom: -30px; margin-right: -30px;
    outline: none; /* Prevent dragging from highlighting the element */
    padding-bottom: 30px;
    position: relative;
  }

  .CodeMirror-sizer {
    border-right: 30px solid transparent;
    position: relative;
  }

  /* The fake, visible scrollbars. Used to force redraw during scrolling
     before actual scrolling happens, thus preventing shaking and
     flickering artifacts. */
  .CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    display: none;
    position: absolute;
    z-index: 6;
  }

  .CodeMirror-vscrollbar {
    overflow-x: hidden;
    overflow-y: scroll;
    right: 0; top: 0;
  }

  .CodeMirror-hscrollbar {
    bottom: 0; left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .CodeMirror-scrollbar-filler {
    right: 0; bottom: 0;
  }

  .CodeMirror-gutter-filler {
    left: 0; bottom: 0;
  }

  .CodeMirror-gutters {
    min-height: 100%;
    position: absolute; left: 0; top: 0;
    z-index: 3;
  }

  .CodeMirror-gutter {
    display: inline-block;
    height: 100%;
    margin-bottom: -30px;
    vertical-align: top;
    white-space: normal;
  }

  .CodeMirror-gutter-wrapper {
    background: none !important;
    border: none !important;
    position: absolute;
    z-index: 4;
  }

  .CodeMirror-gutter-background {
    position: absolute;
    top: 0; bottom: 0;
    z-index: 4;
  }

  .CodeMirror-gutter-elt {
    cursor: default;
    position: absolute;
    z-index: 4;
  }

  .CodeMirror-gutter-wrapper ::selection { background-color: transparent }
  .CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent }
  .CodeMirror-lines {
    cursor: text;
    min-height: 1px; /* prevents collapsing before first draw */
  }

  .CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
    background: transparent;
    border-radius: 0;
    border-width: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-variant-ligatures: contextual;
    line-height: inherit;
    margin: 0;
    overflow: visible;
    position: relative;
    white-space: pre;
    word-wrap: normal;
    z-index: 2;
  }

  .CodeMirror-wrap pre.CodeMirror-line,
  .CodeMirror-wrap pre.CodeMirror-line-like {
    white-space: pre-wrap;
    word-break: normal;
    word-wrap: break-word;
  }

  .CodeMirror-linebackground {
    left: 0; right: 0; top: 0; bottom: 0;
    position: absolute;
    z-index: 0;
  }

  .CodeMirror-linewidget {
    padding: 0.1px; /* Force widget margins to stay inside of the container */
    position: relative;
    z-index: 2;
  }

  .CodeMirror-widget {}

  .CodeMirror-rtl pre { direction: rtl; }

  .CodeMirror-code {
    outline: none;
  }

  /* Force content-box sizing for the elements where we expect it */
  .CodeMirror-scroll,
  .CodeMirror-sizer,
  .CodeMirror-gutter,
  .CodeMirror-gutters,
  .CodeMirror-linenumber {
    box-sizing: content-box;
  }

  .CodeMirror-measure {
    height: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    width: 100%;
  }

  .CodeMirror-cursor {
    position: absolute;
    pointer-events: none;
  }

  .CodeMirror-measure pre { position: static; }

  div.CodeMirror-cursors {
    position: relative;
    visibility: hidden;
    z-index: 3;
  }

  div.CodeMirror-dragcursors {
    visibility: visible;
  }

  .CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible;
  }

  .CodeMirror-selected { background: #d9d9d9; }
  .CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }
  .CodeMirror-crosshair { cursor: crosshair; }
  .CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0; }
  .CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0; }

  .cm-searching {
    background-color: #ffa;
    background-color: rgba(255, 255, 0, .4);
  }

  /* Used to force a border model for a node */
  .cm-force-border { padding-right: .1px; }

  @media print {
    /* Hide the cursor when printing */
    .CodeMirror div.CodeMirror-cursors {
      visibility: hidden;
    }
  }

  /* See issue #2901 */
  .cm-tab-wrap-hack:after { content: ''; }

  /* Help users use markselection to safely style text background */
  span.CodeMirror-selectedtext { background: none; }

  [class^='CodeMirror'] {
    font-family: Inconsolata;
  }

  [class^='CodeMirror'],
  [class^='CodeMirror']::before,
  [class^='CodeMirror']::after,
  [class^='CodeMirror'] *,
  [class^='CodeMirror'] *::before,
  [class^='CodeMirror'] *::after {
    contain: initial;
    line-height: initial;
    pointer-events: auto;
  }

  [class^='CodeMirror'] span {
    display: initial;
  }

  .CodeMirror {
    background-color: transparent;
  }

  .CodeMirror pre {
    padding: 0;
  }

  .CodeMirror-lines {
    padding: 0;
  }
`;

export default StyledCodeMirrorReset;
