// @flow strict-local

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledBody2Text = styled(StyledTypographyText)`
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: -3px 0;
`;

export const StyledBody2Text = RawStyledBody2Text;
