// @flow strict

import React, { Fragment, lazy, Suspense } from 'react';

const DeferredRequestAccessDialog = lazy(() => import('./RequestAccessDialog'));

const AsyncRequestAccessDialog = (props: DialogProps) => (
  <Suspense fallback={Fragment}>
    <DeferredRequestAccessDialog {...props} />
  </Suspense>
);

export default AsyncRequestAccessDialog;
