// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import { StyledMassiveHero } from '../../styled-components/MassiveHero/MassiveHero';
import type { MassiveHeroProps } from './MassiveHeroProps';

const MassiveHero = (props: MassiveHeroProps, ref: mixed) => {
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const { backgroundColor = parentBackgroundColor, offsetBy = null, ...rest } = props;

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <StyledMassiveHero {...rest} ref={ref} $offsetBy={offsetBy} />
    </BackgroundColorContext.Provider>
  );
};

export default forwardRef(MassiveHero);
