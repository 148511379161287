// @flow strict

import styled from 'styled-components';
import type { StyledGridCellProps } from './GridCellProps';
import { StyledSpacer } from '../Spacer/Spacer';

const getGridColumn = (props: StyledGridCellProps) => {
  const { $columnOffset, $columnSpan } = props;
  if ($columnOffset == null && $columnSpan == null) return undefined;
  if ($columnOffset == null) return `span ${props.$columnSpan}`;
  if ($columnSpan == null) return `${props.$columnOffset + 1}`;
  return `${props.$columnOffset + 1} / span ${props.$columnSpan}`;
};

export const RawStyledGridCell = styled(StyledSpacer)`
  contain: layout;
  grid-column: ${(props) => getGridColumn(props)};
  text-align: ${props => props.$alignment};
`;

export const StyledGridCell = RawStyledGridCell;
