// @flow strict-local

import styled, { css, keyframes } from 'styled-components';
import { acceleratedTimingFunction, deceleratedTimingFunction } from '../Animation/variables';
import { heroEnterAnimationDuration, heroExitAnimationDuration } from './variables';
import type { StyledHeroContentProps } from './HeroContentProps';

const animateEnterName = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const animateEnterBackwardsName = keyframes`
  from {
    opacity: 0;
    transform: translateX(24px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animateEnterForwardsName = keyframes`
  from {
    opacity: 0;
    transform: translateX(-24px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animateExitName = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const animateExitBackwardsName = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-24px);
  }
`;

const animateExitForwardsName = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(24px);
  }
`;

const getAnimation = (props: StyledHeroContentProps) => {
  const { $transitionDirection, $transitionState } = props;

  switch ($transitionState) {
    case 'entering': {
      let animateName = animateEnterName;

      if ($transitionDirection === 'backwards') {
        animateName = animateEnterBackwardsName;
      } else if ($transitionDirection === 'forwards') {
        animateName = animateEnterForwardsName;
      }

      return css`
        animation-duration: ${heroEnterAnimationDuration}ms;
        animation-name: ${animateName};
        animation-timing-function: ${deceleratedTimingFunction};
      `;
    }
    case 'exiting': {
      let animateName = animateExitName;

      if ($transitionDirection === 'backwards') {
        animateName = animateExitBackwardsName;
      } else if ($transitionDirection === 'forwards') {
        animateName = animateExitForwardsName;
      }

      return css`
        animation-duration: ${heroExitAnimationDuration}ms;
        animation-name: ${animateName};
        animation-timing-function: ${acceleratedTimingFunction};
      `;
    }
    default:
      return undefined;
  }
};

export const RawStyledHeroContent = styled.div`
  ${getAnimation}
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
`;

export const StyledHeroContent = RawStyledHeroContent;
