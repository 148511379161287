// @flow strict

import React, { useContext } from 'react';
import ModalRefContext from './CanvasModalRefContext';
import { StyledCanvasModal } from '../../styled-components/Canvas/CanvasModal';
import type { CanvasModalProps } from './CanvasModalProps';

const CanvasModal = (props: CanvasModalProps) => (
  <StyledCanvasModal
    {...props}
    ref={useContext(ModalRefContext)}
  />
);

export default CanvasModal;
