// @flow strict-local

import { rgb } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledPage = styled.section`
  background-color: ${props => rgb(getColorRgb(props.$backgroundColor))};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
  position: absolute;
  width: 100%;
`;

export const StyledPage = RawStyledPage;
