// @flow strict-local

import { rgba } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';
import getEmphasisOpacity from '../Palette/getEmphasisOpacity';

export const RawStyledText = styled.span`
  color: ${(props) => (props.$color ? rgba({ ...getColorRgb(props.$color), alpha: getEmphasisOpacity(props.$emphasis) }) : 'inherit')};
  display: ${props => (props.$isBlock ? 'block' : 'inline')};
  font-family: ${props => (props.$isCode ? 'Inconsolata' : 'inherit')};
  font-weight: ${props => (props.$weight ? props.$weight : 'inherit')};
  font-size: inherit;
  font-style: ${props => (props.$isItalic ? 'italic' : undefined)};
  line-height: inherit;
  pointer-events: inherit;
  user-select: inherit;
`;

export const StyledText = RawStyledText;
