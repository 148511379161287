// @flow strict

type CreateSpeechRecognizerOptions = {|
  continuous?: void | boolean,
  interimResults?: void | boolean,
  onEnd?: void | () => void,
  onResult?: void | (e: mixed) => void,
  onStart?: void | () => void,
|};

window.SpeechRecognition = window.SpeechRecognition ?? window.webkitSpeechRecognition;

export default (opts: CreateSpeechRecognizerOptions) => {
  const { continuous, interimResults, onEnd, onResult, onStart } = opts;

  const speechRecognizer = new window.SpeechRecognition();
  speechRecognizer.continuous = continuous;
  speechRecognizer.interimResults = interimResults;
  speechRecognizer.onend = onEnd;
  speechRecognizer.onresult = onResult;
  speechRecognizer.onstart = onStart;

  return speechRecognizer;
};
