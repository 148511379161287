// @flow strict

import React from 'react';
import { StyledHeadline6Text } from '../../styled-components/Headline6/Headline6Text';
import type { Headline6TextProps } from './Headline6TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Headline6Text = (props: Headline6TextProps) => (
  <StyledHeadline6Text {...props} />
);

export default withTypographyText(Headline6Text);
