// @flow strict

import { rgb, rgba } from 'polished';
import styled from 'styled-components';
import { StyledButtonBody } from '../Button/ButtonBody';
import { RawStyledOutlinedButton } from './OutlinedButton';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledOutlinedButtonBody = styled(StyledButtonBody)`
  border: 2px solid ${props => rgb(getColorRgb(props.$backgroundColor))};
  border-radius: 4px;

  ${RawStyledOutlinedButton}:hover & {
    background-color: ${props => rgba({ ...getColorRgb(props.$backgroundColor), alpha: 0.08 })};
  }

  ${RawStyledOutlinedButton}:focus & {
    background-color: ${props => rgba({ ...getColorRgb(props.$backgroundColor), alpha: 0.16 })};
  }

  ${RawStyledOutlinedButton}:active & & {
    background-color: ${props => rgba({ ...getColorRgb(props.$backgroundColor), alpha: 0.24 })};
  }
`;

export const StyledOutlinedButtonBody = RawStyledOutlinedButtonBody;
