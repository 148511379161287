// @flow strict

import styled from 'styled-components';
import Svg from '../../images/logo_text.svg';

export const RawStyledLogoTextImage = styled(Svg)`
  width: 138.33px;
`;

export const StyledLogoTextImage = RawStyledLogoTextImage;
