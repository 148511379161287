// @flow strict

import styled from 'styled-components';

export const RawStyledLogoButton = styled.button`
  cursor: pointer;
  display: inline-block;
  pointer-events: auto;
`;

export const StyledLogoButton = RawStyledLogoButton;
