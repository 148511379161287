// @flow strict

import React, { useContext } from 'react';
import { StyledTabGroupSelectorHead } from '../../styled-components/Tab/TabGroupSelectorHead';
import TabGroupColorContext from './TabGroupColorContext';
import TabGroupSelectedTabIndexContext from './TabGroupSelectedTabIndexContext';
import TabGroupSelectorTrackDimsContext from './TabGroupSelectorTrackDimsContext';
import TabGroupTabBodyContentDimsContext from './TabGroupTabBodyContentDimsContext';
import type { TabGroupSelectorHeadProps } from './TabGroupSelectorHeadProps';
import usePrevious from '../../hooks/usePrevious';

const TabGroupSelectorHead = (props: TabGroupSelectorHeadProps) => {
  const { color = useContext(TabGroupColorContext), ...rest } = props;
  const selectedTabIndex = useContext(TabGroupSelectedTabIndexContext);
  const tabBodyContentDims = useContext(TabGroupTabBodyContentDimsContext);
  const trackDims = useContext(TabGroupSelectorTrackDimsContext);
  const trackX = trackDims.x;
  let nextOffset = null;
  let nextWidth = null;

  if (selectedTabIndex !== -1 && trackX != null) {
    const selectedTabBodyContentDims = tabBodyContentDims[selectedTabIndex];

    if (selectedTabBodyContentDims != null) {
      nextOffset = selectedTabBodyContentDims.x - trackX;
      nextWidth = selectedTabBodyContentDims.width;
    }
  }

  const prevOffset = usePrevious(nextOffset);
  const prevWidth = usePrevious(nextWidth);

  let animationType = 'slide';
  let offset = nextOffset;
  let width = nextWidth;

  if (nextOffset == null) {
    animationType = 'fadeOut';
    offset = prevOffset;
    width = prevWidth;
  } else if (prevOffset == null) {
    animationType = 'fadeIn';
  }

  return (
    <StyledTabGroupSelectorHead
      $animationType={animationType}
      $color={color}
      $offset={offset}
      $width={width}
      {...rest}
    />
  );
};

export default TabGroupSelectorHead;
