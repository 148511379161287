// @flow strict-local

import React, { memo, useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation } from 'react-router';
import RootDrawerSetIsOpenContext from './RootDrawerSetIsOpenContext';
import RouterLink from '../RouterLink';
import RootPageHeaderHeroGroup from './RootPageHeaderHeroGroup';

import {
  BackgroundColorContext,
  Breakpoint,
  Grid,
  GridCell,
  Icon,
  IconButton,
  IconButtonBody,
  IconButtonBodyIcon,
  IconImage,
  Layout,
  LayoutItem,
  Logo,
  LogoButton,
  LogoIcon,
  LogoIconImage,
  LogoText,
  LogoTextImage,
  MenuSvg,
  OutlinedButton,
  OutlinedButtonBody,
  OutlinedButtonBodyText,
  PageContainer,
  Tab,
  TabBody,
  TabBodyContent,
  TabBodyContentText,
  TabBodyInk,
  TabGroup,
  TabGroupSelector,
  TabGroupSelectorHead,
  TabGroupTab,
  TabGroupTabs,
  TextButton,
  TextButtonBody,
  TextButtonBodyText,
  Toolbar,
  ToolbarBody,
  ToolbarBodyPrimary,
  ToolbarBodySecondary,
  useGlobalDialogById,
} from '../../../../..';

const tabs = (
  <>
    <TabGroupTab>
      <Tab as={RouterLink} to="/developer/documentation">
        <TabBody>
          <TabBodyInk />
          <TabBodyContent>
            <TabBodyContentText>
              <FormattedMessage id="DEVELOPER" />
            </TabBodyContentText>
          </TabBodyContent>
        </TabBody>
      </Tab>
    </TabGroupTab>
    <TabGroupTab>
      <Tab as={RouterLink} to="/pricing">
        <TabBody>
          <TabBodyInk />
          <TabBodyContent>
            <TabBodyContentText>
              <FormattedMessage id="PRICING" />
            </TabBodyContentText>
          </TabBodyContent>
        </TabBody>
      </Tab>
    </TabGroupTab>
    <TabGroupTab>
      <Tab as={RouterLink} to="/about">
        <TabBody>
          <TabBodyInk />
          <TabBodyContent>
            <TabBodyContentText>
              <FormattedMessage id="ABOUT" />
            </TabBodyContentText>
          </TabBodyContent>
        </TabBody>
      </Tab>
    </TabGroupTab>
  </>
);

const routesOrder = [
  { path: '/developer' },
  { path: '/pricing' },
  { path: '/about' },
];

const routesWithSubToolbars = [
  { path: '/developer/documentation' },
];

const routesWithBigHeros = [
  { exact: true, path: '/' },
];

const routesWithAnyHeros = [
  ...routesWithBigHeros,
  { exact: true, path: '/developer/documentation' },
  { exact: true, path: '/pricing' },
  { exact: true, path: '/about' },
];

const RootPageHeader = () => {
  const setNavigationIsOpen = useContext(RootDrawerSetIsOpenContext);
  const showNavigation = useCallback(() => setNavigationIsOpen(true), [setNavigationIsOpen]);
  const { show: showRequestAccessDialog } = useGlobalDialogById('REQUEST_ACCESS');
  const { show: showSignInDialog } = useGlobalDialogById('SIGN_IN');
  const location = useLocation();
  const { pathname } = location;
  const selectedTabIndex = routesOrder.findIndex(route => matchPath(pathname, route));
  const hasBigHero = routesWithBigHeros.some(route => matchPath(pathname, route));
  const hasAnyHero = routesWithAnyHeros.some(route => matchPath(pathname, route));
  const hasSubToolbar = routesWithSubToolbars.some(route => matchPath(pathname, route));
  let heroColor = useContext(BackgroundColorContext);
  let toolbarColor = 'darkPrimary';
  let toolbarIsFixed = true;

  if (hasAnyHero) {
    heroColor = 'darkPrimary';
    toolbarColor = null;
    toolbarIsFixed = false;
  }

  if (hasSubToolbar) {
    toolbarIsFixed = false;
  }

  return (
    <>
      <Breakpoint end={4}>
        <Toolbar as="header" backgroundColor={toolbarColor} isExtended isFixed={toolbarIsFixed} parentBackgroundColor={heroColor}>
          <ToolbarBody>
            <PageContainer>
              <ToolbarBodyPrimary>
                <Layout justifyContent="space-between">
                  <LayoutItem>
                    <LogoButton as={RouterLink} to="/">
                      <Logo>
                        <LogoIcon>
                          <LogoIconImage />
                        </LogoIcon>
                        <LogoText>
                          <LogoTextImage />
                        </LogoText>
                      </Logo>
                    </LogoButton>
                  </LayoutItem>
                  <LayoutItem alignment="right">
                    <IconButton onClick={showNavigation}>
                      <IconButtonBody>
                        <IconButtonBodyIcon>
                          <Icon>
                            <IconImage>
                              <MenuSvg />
                            </IconImage>
                          </Icon>
                        </IconButtonBodyIcon>
                      </IconButtonBody>
                    </IconButton>
                  </LayoutItem>
                </Layout>
              </ToolbarBodyPrimary>
              <ToolbarBodySecondary>
                <TabGroup color="primary" selectedTabIndex={selectedTabIndex}>
                  <TabGroupSelector>
                    <TabGroupSelectorHead />
                  </TabGroupSelector>
                  <TabGroupTabs areEqualWidth>
                    {tabs}
                  </TabGroupTabs>
                </TabGroup>
              </ToolbarBodySecondary>
            </PageContainer>
          </ToolbarBody>
        </Toolbar>
      </Breakpoint>
      <Breakpoint end={7} start={4}>
        <Toolbar as="header" backgroundColor={toolbarColor} isExtended isFixed={toolbarIsFixed} parentBackgroundColor={heroColor}>
          <ToolbarBody>
            <PageContainer>
              <ToolbarBodyPrimary>
                <Layout justifyContent="space-between">
                  <LayoutItem>
                    <LogoButton as={RouterLink} to="/">
                      <Logo>
                        <LogoIcon>
                          <LogoIconImage />
                        </LogoIcon>
                        <LogoText>
                          <LogoTextImage />
                        </LogoText>
                      </Logo>
                    </LogoButton>
                  </LayoutItem>
                  <LayoutItem alignment="right">
                    <TextButton color="primary" onClick={showSignInDialog}>
                      <TextButtonBody>
                        <TextButtonBodyText>
                          <FormattedMessage id="SIGN_IN" />
                        </TextButtonBodyText>
                      </TextButtonBody>
                    </TextButton>
                    <OutlinedButton backgroundColor="primary" onClick={showRequestAccessDialog}>
                      <OutlinedButtonBody>
                        <OutlinedButtonBodyText>
                          <FormattedMessage id="REQUEST_ACCESS" />
                        </OutlinedButtonBodyText>
                      </OutlinedButtonBody>
                    </OutlinedButton>
                  </LayoutItem>
                </Layout>
              </ToolbarBodyPrimary>
              <ToolbarBodySecondary>
                <TabGroup color="primary" selectedTabIndex={selectedTabIndex}>
                  <TabGroupSelector>
                    <TabGroupSelectorHead />
                  </TabGroupSelector>
                  <TabGroupTabs areEqualWidth>
                    {tabs}
                  </TabGroupTabs>
                </TabGroup>
              </ToolbarBodySecondary>
            </PageContainer>
          </ToolbarBody>
        </Toolbar>
      </Breakpoint>
      <Breakpoint start={7}>
        <Toolbar as="header" backgroundColor={toolbarColor} isFixed={toolbarIsFixed} parentBackgroundColor={heroColor}>
          <ToolbarBody>
            <PageContainer>
              <ToolbarBodyPrimary>
                <Grid>
                  <GridCell columnSpan={6}>
                    <LogoButton as={RouterLink} to="/">
                      <Logo>
                        <LogoIcon>
                          <LogoIconImage />
                        </LogoIcon>
                        <LogoText>
                          <LogoTextImage />
                        </LogoText>
                      </Logo>
                    </LogoButton>
                  </GridCell>
                  <GridCell columnSpan={9}>
                    <TabGroup color="primary" selectedTabIndex={selectedTabIndex}>
                      <TabGroupSelector>
                        <TabGroupSelectorHead />
                      </TabGroupSelector>
                      <TabGroupTabs>
                        {tabs}
                      </TabGroupTabs>
                    </TabGroup>
                  </GridCell>
                  <GridCell columnSpan={9}>
                    <Layout justifyContent="flex-end">
                      <TextButton color="primary" onClick={showSignInDialog}>
                        <TextButtonBody>
                          <TextButtonBodyText>
                            <FormattedMessage id="SIGN_IN" />
                          </TextButtonBodyText>
                        </TextButtonBody>
                      </TextButton>
                      <OutlinedButton backgroundColor="primary" onClick={showRequestAccessDialog}>
                        <OutlinedButtonBody>
                          <OutlinedButtonBodyText>
                            <FormattedMessage id="REQUEST_ACCESS" />
                          </OutlinedButtonBodyText>
                        </OutlinedButtonBody>
                      </OutlinedButton>
                    </Layout>
                  </GridCell>
                </Grid>
              </ToolbarBodyPrimary>
            </PageContainer>
          </ToolbarBody>
        </Toolbar>
      </Breakpoint>
      {!(hasAnyHero && !hasBigHero) ? null : (
        <>
          <Breakpoint end={7}>
            <RootPageHeaderHeroGroup
              index={selectedTabIndex}
              offsetBy="toolbarExtended"
            />
          </Breakpoint>
          <Breakpoint start={7}>
            <RootPageHeaderHeroGroup
              index={selectedTabIndex}
              offsetBy="toolbar"
            />
          </Breakpoint>
        </>
      )}
    </>
  );
};

export default memo(RootPageHeader);
