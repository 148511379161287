// @flow strict-local

import React, { forwardRef } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import { StyledPage } from '../../styled-components/Page/Page';
import type { PageProps } from './PageProps';

const Page = (props: PageProps, ref: mixed) => {
  const { backgroundColor = 'light' } = props;

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <StyledPage
        {...props}
        ref={ref}
        $backgroundColor={backgroundColor}
      />
    </BackgroundColorContext.Provider>
  );
};

export default forwardRef(Page);
