// @flow strict

import styled from 'styled-components';

export const RawStyledPageBody = styled.main`
  contain: none;
  flex: 1;
`;

export const StyledPageBody = RawStyledPageBody;
