// @flow strict-local

import { rgb, rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import { acceleratedTimingFunction } from '../Animation/variables';
import getColorRgb from '../Palette/getColorRgb';
import { heroExitAnimationDuration } from './variables';
import type { StyledHeroBackgroundProps } from './HeroBackgroundProps';

const createAnimateExitName = (props: StyledHeroBackgroundProps) => keyframes`
  from {
    background-color: ${rgba({ ...getColorRgb(props.$color), alpha: 1 })};
  }

  to {
    background-color: ${rgba({ ...getColorRgb(props.$color), alpha: 0 })};
  }
`;

const getAnimation = (props: StyledHeroBackgroundProps) => {
  const { $transitionState } = props;

  switch ($transitionState) {
    case 'exiting':
      return css`
        animation-duration: ${heroExitAnimationDuration}ms;
        animation-timing-function: ${acceleratedTimingFunction};
        animation-name: ${createAnimateExitName(props)};
      `;
    default:
      return undefined;
  }
};

export const RawStyledHeroBackground = styled.div`
  ${getAnimation}
  background-color: ${props => rgb(getColorRgb(props.$color))};
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledHeroBackground = RawStyledHeroBackground;
