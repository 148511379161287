// @flow strict-local

import * as Sentry from '@sentry/browser';
import invariant from 'invariant';
import React from 'react';
import { render } from 'react-dom';
import Root from './components/Root/Root';
import createApolloClient from './graphql/client/createClient';

const env = process.env.ENV;
const genesantApiUrl = process.env.GENESANT_API_URL;
const sentryDsn = process.env.SENTRY_DSN;
const version = process.env.VERSION;
const rootEl = document.getElementById('root');

invariant(env != null, 'env var “ENV” is not set');
invariant(genesantApiUrl != null, 'env var “GENESANT_API_URL” is not set');
invariant(sentryDsn !== undefined, 'env var “SENTRY_DSN” is not set');
invariant(version !== null, 'env var “VERSION” is not set');
invariant(rootEl !== null, '#root element not found. Cannot mount react app.');

const run = async () => {
  Sentry.init({
    dsn: sentryDsn,
    environment: env,
    release: version,
  });

  if (!global.ResizeObserver) {
    global.ResizeObserver = (await import('resize-observer-polyfill')).default;
  }

  const apolloClient = await createApolloClient({ genesantApiUrl });

  render(<Root apolloClient={apolloClient} />, rootEl);
};

run();
