// @flow strict

import { rgb, rgba } from 'polished';
import styled from 'styled-components';
import { standardTimingFunction } from '../Animation/variables';
import { StyledButtonBody } from '../Button/ButtonBody';
import { RawStyledContainedButton } from './ContainedButton';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledContainedButtonBody = styled(StyledButtonBody)`
  background-color: ${props => rgb(getColorRgb(props.$backgroundColor))};
  border-radius: 4px;

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition-duration: 150ms;
    transition-property: background-color;
    transition-timing-function: ${standardTimingFunction};
  }

  ${RawStyledContainedButton}:hover &::before {
    background-color: ${props => rgba({ ...getColorRgb(props.$contrastColor), alpha: 0.16 })};
  }

  ${RawStyledContainedButton}:focus &::before {
    background-color: ${props => rgba({ ...getColorRgb(props.$contrastColor), alpha: 0.32 })};
  }

  ${RawStyledContainedButton}:active &::before & {
    background-color: ${props => rgba({ ...getColorRgb(props.$contrastColor), alpha: 0.48 })};
  }
`;

export const StyledContainedButtonBody = RawStyledContainedButtonBody;
