// @flow strict

import styled from 'styled-components';
import { RawStyledSpacer } from '../Spacer/Spacer';

export const RawStyledLayoutItem = styled(RawStyledSpacer)`
  contain: layout;
  flex: ${props => props.$flexGrow};
  text-align: ${props => props.$alignment};
`;

export const StyledLayoutItem = RawStyledLayoutItem;
