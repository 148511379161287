// @flow strict

import styled from 'styled-components';

export const RawStyledIconButton = styled.button`
  contain: layout;
  cursor: ${props => (props.$isDisabled || props.$isUnclickable ? undefined : 'pointer')};
  display: inline-block;
  padding: 6px;
  pointer-events: ${props => (props.$isDisabled || props.$isUnclickable ? undefined : 'auto')};
`;

export const StyledIconButton = RawStyledIconButton;
