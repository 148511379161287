// @flow strict

import alertCircleSvgUrl from '@mdi/svg/svg/alert-circle.svg?inline'; // eslint-disable-line import/no-unresolved
import alertSvgUrl from '@mdi/svg/svg/alert.svg?inline'; // eslint-disable-line import/no-unresolved
import { rgb } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { acceleratedTimingFunction } from '../Animation/variables';
import { captionFontSize, captionLineHeight } from '../Caption/variables';
import { denseIconPadding, denseIconSize } from '../Icon/variables';
import { modalBorderRadius, modalBoxShadow, modalFadeOutDuration } from '../Modal/variables';
import { negativeColorRgb, xLightColorRgb } from '../Palette/variables';

const StyledCodeMirrorLint = createGlobalStyle`
  .CodeMirror-lint-tooltip {
    background-color: ${rgb(xLightColorRgb)};
    border-radius: ${modalBorderRadius}px;
    box-shadow: ${modalBoxShadow};
    font-family: Inconsolata;
    font-size: ${captionFontSize}px;
    height: ${denseIconSize}px;
    line-height: ${captionLineHeight}px;
    padding: ${denseIconPadding}px;
    padding-left: ${denseIconSize}px;
    position: fixed;
    transition-duration: ${modalFadeOutDuration}ms;
    transition-property: opacity;
    transition-timing-function: ${acceleratedTimingFunction};
    z-index: 100;
  }

  .CodeMirror-lint-message-error,
  .CodeMirror-lint-message-warning {
    &::before {
      background-color: ${rgb(negativeColorRgb)};
      content: '';
      height: ${denseIconSize - (2 * denseIconPadding)}px;
      left: ${denseIconPadding}px;
      position: absolute;
      top: ${denseIconPadding}px;
      width: ${denseIconSize - (2 * denseIconPadding)}px;
    }
  }

  .CodeMirror-lint-message-error::before {
    mask-image: url("${alertCircleSvgUrl}");
  }

  .CodeMirror-lint-message-warning::before {
    mask-image: url("${alertSvgUrl}");
  }
`;

export default StyledCodeMirrorLint;
