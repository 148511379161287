// @flow strict

import styled from 'styled-components';
import Svg from '../../images/logo_icon.svg';

export const RawStyledLogoIconImage = styled(Svg)`
  width: 30px;
`;

export const StyledLogoIconImage = RawStyledLogoIconImage;
