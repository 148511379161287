// @flow strict

import React, { forwardRef, useContext } from 'react';
import TransitionStateContext from '../Transition/TransitionStateContext';
import { StyledMassiveHeroBackground } from '../../styled-components/MassiveHero/MassiveHeroBackground';
import type { MassiveHeroBackgroundProps } from './MassiveHeroBackgroundProps';

const MassiveHeroBackground = (props: MassiveHeroBackgroundProps, ref: mixed) => (
  <StyledMassiveHeroBackground
    {...props}
    ref={ref}
    $transitionState={useContext(TransitionStateContext)}
  />
);

export default forwardRef(MassiveHeroBackground);
