// @flow strict-local

import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import RouterLink from '../RouterLink';

import {
  Body2,
  Body2Text,
  Breakpoint,
  Divider,
  GitHubSvg,
  Grid,
  GridCell,
  Icon,
  IconButton,
  IconButtonBody,
  IconButtonBodyIcon,
  IconImage,
  Layout,
  LayoutItem,
  Logo,
  LogoButton,
  LogoIcon,
  LogoIconImage,
  LogoText,
  LogoTextImage,
  PageContainer,
  PageFooter,
  Section,
  Subtitle2,
  Subtitle2Text,
  TextButton,
  TextButtonBody,
  TextButtonBodyText,
} from '../../../../..';

const copyrightTranslationValues = {
  year: new Date().getFullYear(),
};

const product = (
  <>
    <Subtitle2 emphasis="high" spaceBottom="small">
      <Subtitle2Text>
        <FormattedMessage id="PRODUCT" />
      </Subtitle2Text>
    </Subtitle2>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" smooth to="/#try-it-out">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="TRY_IT_OUT" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" smooth to="/#use-cases">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="USE_CASES" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" smooth to="/#examples">
        <TextButtonBody>
          <TextButtonBodyText>
            Examples
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
  </>
);

const information = (
  <>
    <Subtitle2 emphasis="high" spaceBottom="small">
      <Subtitle2Text>
        <FormattedMessage id="INFORMATION" />
      </Subtitle2Text>
    </Subtitle2>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" to="/pricing">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="PRICING" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" to="/developer/documentation">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="DOCUMENTATION" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" to="/developer">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="SUPPORT" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
  </>
);

const company = (
  <>
    <Subtitle2 emphasis="high" spaceBottom="small">
      <Subtitle2Text>
        <FormattedMessage id="COMPANY" />
      </Subtitle2Text>
    </Subtitle2>
    <div>
      <TextButton as="a" color="primary" density={4} href="mailto:hello@genesant.ai" size="small">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="CONTACT_US" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" to="/about">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="ABOUT_US" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
    <div>
      <TextButton as={RouterLink} color="primary" density={4} size="small" smooth to="/about#the-team">
        <TextButtonBody>
          <TextButtonBodyText>
            <FormattedMessage id="THE_TEAM" />
          </TextButtonBodyText>
        </TextButtonBody>
      </TextButton>
    </div>
  </>
);

export const legal = (
  <>
    <TextButton as={RouterLink} density={4} size="small" to="/terms">
      <TextButtonBody>
        <TextButtonBodyText>
          <FormattedMessage id="TERMS_OF_SERVICE" />
        </TextButtonBodyText>
      </TextButtonBody>
    </TextButton>
    <TextButton as={RouterLink} density={4} size="small" to="/privacy">
      <TextButtonBody>
        <TextButtonBodyText>
          <FormattedMessage id="PRIVACY_POLICY" />
        </TextButtonBodyText>
      </TextButtonBody>
    </TextButton>
  </>
);

const RootPageFooter = () => (
  <PageFooter>
    <Section backgroundColor="darkPrimary">
      <PageContainer>
        <Breakpoint end={4}>
          <Grid>
            <GridCell columnSpan={4} spaceTop="medium">
              {product}
            </GridCell>
            <GridCell columnSpan={4} spaceTop="medium">
              {information}
            </GridCell>
            <GridCell columnSpan={4} spaceTop="medium">
              {company}
            </GridCell>
          </Grid>
        </Breakpoint>
        <Breakpoint end={6} start={4}>
          <Grid>
            <GridCell columnSpan={5}>
              {product}
            </GridCell>
            <GridCell columnSpan={5}>
              {information}
            </GridCell>
            <GridCell columnSpan={5}>
              {company}
            </GridCell>
          </Grid>
        </Breakpoint>
        <Breakpoint start={6}>
          <Grid>
            <GridCell columnSpan={8}>
              {product}
            </GridCell>
            <GridCell columnSpan={8}>
              {information}
            </GridCell>
            <GridCell columnSpan={8}>
              {company}
            </GridCell>
          </Grid>
        </Breakpoint>
        <Layout alignItems="center" justifyContent="space-between" spaceTop="large">
          <LayoutItem>
            <LogoButton as={RouterLink} to="/">
              <Logo>
                <LogoIcon>
                  <LogoIconImage />
                </LogoIcon>
                <LogoText>
                  <LogoTextImage />
                </LogoText>
              </Logo>
            </LogoButton>
          </LayoutItem>
          <LayoutItem alignment="end">
            <IconButton as="a" href="https://github.com/genesant" rel="noopener" target="_blank">
              <IconButtonBody>
                <IconButtonBodyIcon>
                  <Icon>
                    <IconImage>
                      <GitHubSvg />
                    </IconImage>
                  </Icon>
                </IconButtonBodyIcon>
              </IconButtonBody>
            </IconButton>
          </LayoutItem>
        </Layout>
        <Divider isFullLength spaceBottom="xxSmall" spaceTop="xSmall" />
        <Breakpoint end={4}>
          {legal}
          <Body2>
            <Body2Text>
              <FormattedMessage
                id="COPYRIGHT_YEAR_AND_COMPANY"
                values={copyrightTranslationValues}
              />
            </Body2Text>
          </Body2>
        </Breakpoint>
        <Breakpoint start={4}>
          <Layout alignItems="center" justifyContent="space-between">
            <LayoutItem>
              {legal}
            </LayoutItem>
            <LayoutItem>
              <Body2 alignment="end">
                <Body2Text>
                  <FormattedMessage
                    id="COPYRIGHT_YEAR_AND_COMPANY"
                    values={copyrightTranslationValues}
                  />
                </Body2Text>
              </Body2>
            </LayoutItem>
          </Layout>
        </Breakpoint>
      </PageContainer>
    </Section>
  </PageFooter>
);

export default memo(RootPageFooter);
