// @flow strict

import React, { useCallback, useContext, useRef } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import getContrastColor from '../Background/getContrastColor';
import ContainedButtonBackgroundColorContext from './ContainedButtonBackgroundColorContext';
import ContainedButtonContrastColorContext from './ContainedButtonContrastColorContext';
import ContainedButtonForegroundColorContext from './ContainedButtonForegroundColorContext';
import { StyledContainedButton } from '../../styled-components/ContainedButton/ContainedButton';
import { containedButtonInkRippleDuration } from '../../styled-components/ContainedButton/variables';
import type { ContainedButtonProps } from './ContainedButtonProps';
import InkColorContext from '../Ink/InkColorContext';
import InkPressesContext from '../Ink/InkPressesContext';
import useInkPresses from '../Ink/useInkPresses';

const ContainedButton = (props: ContainedButtonProps) => {
  const [inkPresses, handleInkPress] = useInkPresses(containedButtonInkRippleDuration);
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const ref = useRef();

  const {
    as = 'button',
    backgroundColor: optionalBackgroundColor,
    foregroundColor: optionalForegroundColor,
    onClick,
    isFullWidth,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  const backgroundColor = optionalBackgroundColor ?? getContrastColor(parentBackgroundColor);
  const contrastColor = getContrastColor(backgroundColor);
  const foregroundColor = optionalForegroundColor ?? contrastColor;

  const handleClick = useCallback((e: HTMLEvent) => {
    if (onClick) onClick(e);
    if (e.target) e.target.blur();
    handleInkPress(e, ref.current);
  }, []);

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <ContainedButtonBackgroundColorContext.Provider value={backgroundColor}>
        <ContainedButtonContrastColorContext.Provider value={contrastColor}>
          <ContainedButtonForegroundColorContext.Provider value={foregroundColor}>
            <InkColorContext.Provider value={contrastColor}>
              <InkPressesContext.Provider value={inkPresses}>
                <StyledContainedButton
                  ref={ref}
                  $backgroundColor={backgroundColor}
                  $foregroundColor={foregroundColor}
                  $isFullWidth={isFullWidth}
                  $spaceBottom={spaceBottom}
                  $spaceLeft={spaceLeft}
                  $spaceRight={spaceRight}
                  $spaceTop={spaceTop}
                  as={as}
                  onClick={handleClick}
                  {...rest}
                />
              </InkPressesContext.Provider>
            </InkColorContext.Provider>
          </ContainedButtonForegroundColorContext.Provider>
        </ContainedButtonContrastColorContext.Provider>
      </ContainedButtonBackgroundColorContext.Provider>
    </BackgroundColorContext.Provider>
  );
};

export default ContainedButton;
