// @flow strict

import React, { memo } from 'react';
import StyledCodeMirrorReset from '../../styled-components/CodeMirror/CodeMirrorReset';
import StyledCodeMirrorDialog from '../../styled-components/CodeMirror/CodeMirrorDialog';
import StyledCodeMirrorLint from '../../styled-components/CodeMirror/CodeMirrorLint';
import StyledCodeMirrorShowHint from '../../styled-components/CodeMirror/CodeMirrorShowHint';

const GraphiQLEditorReset = () => (
  <>
    <StyledCodeMirrorReset />
    <StyledCodeMirrorDialog />
    <StyledCodeMirrorLint />
    <StyledCodeMirrorShowHint />
  </>
);

export default memo(GraphiQLEditorReset);
