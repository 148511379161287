// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import { StyledHeroContent } from '../../styled-components/Hero/HeroContent';
import HeroTransitionDirectionContext from './HeroTransitionDirectionContext';
import TransitionStateContext from '../Transition/TransitionStateContext';
import type { HeroContentProps } from './HeroContentProps';

const HeroContent = (props: HeroContentProps, ref: mixed) => {
  const transitionDirection = useContext(HeroTransitionDirectionContext);
  const transitionState = useContext(TransitionStateContext);

  return (
    <StyledHeroContent
      {...props}
      ref={ref}
      $transitionDirection={transitionDirection}
      $transitionState={transitionState}
    />
  );
};

export default forwardRef(HeroContent);
