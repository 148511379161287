// @flow strict

import React, { useContext } from 'react';
import ParentBackgroundColorContext from '../Background/BackgroundColorContext';
import ToolbarBackgroundColorContext from './ToolbarBackgroundColorContext';
import ToolbarDensityContext from './ToolbarDensityContext';
import { StyledToolbar } from '../../styled-components/Toolbar/Toolbar';
import type { ToolbarProps } from './ToolbarProps';

const Toolbar = (props: ToolbarProps) => {
  const defaultParentBackgroundColor = useContext(ParentBackgroundColorContext);

  const {
    backgroundColor,
    density = 0,
    isExtended = false,
    isFixed = false,
    parentBackgroundColor = defaultParentBackgroundColor,
  } = props;

  return (
    <ParentBackgroundColorContext.Provider value={backgroundColor ?? parentBackgroundColor}>
      <ToolbarBackgroundColorContext.Provider value={backgroundColor}>
        <ToolbarDensityContext.Provider value={density}>
          <StyledToolbar
            $density={density}
            $isExtended={isExtended}
            $isFixed={isFixed}
            {...props}
          />
        </ToolbarDensityContext.Provider>
      </ToolbarBackgroundColorContext.Provider>
    </ParentBackgroundColorContext.Provider>
  );
};

export default Toolbar;
