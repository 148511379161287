// @flow strict

import type { Space } from './Space';

import {
  largeSpaceSize,
  mediumSpaceSize,
  smallSpaceSize,
  xLargeSpaceSize,
  xSmallSpaceSize,
  xxLargeSpaceSize,
  xxxLargeSpaceSize,
  xxSmallSpaceSize,
  xxxSmallSpaceSize,
} from './variables';

export default (space: Space) => {
  if (typeof space === 'number') {
    return 4 * space;
  }

  switch (space) {
    case 'large':
      return largeSpaceSize;
    case 'medium':
      return mediumSpaceSize;
    case 'small':
      return smallSpaceSize;
    case 'xLarge':
      return xLargeSpaceSize;
    case 'xSmall':
      return xSmallSpaceSize;
    case 'xxLarge':
      return xxLargeSpaceSize;
    case 'xxSmall':
      return xxSmallSpaceSize;
    case 'xxxLarge':
      return xxxLargeSpaceSize;
    case 'xxxSmall':
      return xxxSmallSpaceSize;
    default:
      console.error(`Unknown space “${space}”`);
      return 0;
  }
};
