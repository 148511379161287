// @flow strict

import styled from 'styled-components';
import { toolbarExtendedHeight, toolbarHeight } from '../Toolbar/variables';
import { massiveHeroHeightRatio, massiveHeroMaximumHeight, massiveHeroMinimumHeight } from './variables';
import type { StyledMassiveHeroProps } from './MassiveHeroProps';

const getOffsetSize = (props: StyledMassiveHeroProps) => {
  switch (props.$offsetBy) {
    case 'toolbar':
      return toolbarHeight;
    case 'toolbarExtended':
      return toolbarExtendedHeight;
    default:
      return 0;
  }
};

export const RawStyledMassiveHero = styled.div`
  contain: size;
  display: flex;
  flex-direction: column;
  height: ${100 * massiveHeroHeightRatio}vh;
  margin: -${props => getOffsetSize(props)}px auto 0;
  max-height: ${massiveHeroMaximumHeight}px;
  min-height: ${massiveHeroMinimumHeight}px;
  padding: ${props => getOffsetSize(props)}px 8px 0;
  position: relative;

  @media (min-width: 720px) {
    max-width: 1304px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const StyledMassiveHero = RawStyledMassiveHero;
