// @flow strict-local

import { TransitionGroup } from '@material-ui/react-transition-group';
import React from 'react';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import AboutPageHero from '../AboutPage/AboutPageHero';
import DocumentationPageHero from '../DocumentationPage/DocumentationPageHero';
import PricingPageHero from '../PricingPage/PricingPageHero';
import { HeroTransition, HeroGroup, type HeroGroupProps } from '../../../../..';

const RootPageHeaderHeroGroup = (props: HeroGroupProps) => {
  const { index, ...rest } = props;
  const location = useLocation();

  return (
    <HeroGroup {...rest} index={index}>
      <TransitionGroup appear component={null}>
        <HeroTransition key={index} direction>
          <Switch location={location}>
            <Route
              component={DocumentationPageHero}
              exact
              path="/developer/documentation"
            />
            <Route
              component={PricingPageHero}
              exact
              path="/pricing"
            />
            <Route
              component={AboutPageHero}
              exact
              path="/about"
            />
          </Switch>
        </HeroTransition>
      </TransitionGroup>
    </HeroGroup>
  );
};

export default RootPageHeaderHeroGroup;
