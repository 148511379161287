// @flow strict

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledTitleText = styled(StyledTypographyText)`
  font-size: 28px;
  font-weight: 300;
  line-height: 32px;
  margin: -2px 0;
  text-align: center;
`;

export const StyledTitleText = RawStyledTitleText;
