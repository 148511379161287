// @flow strict-local

import { rgba } from 'polished';
import styled from 'styled-components';
import { StyledButtonBody } from '../Button/ButtonBody';
import { RawStyledTextButton } from './TextButton';
import getColorRgb from '../Palette/getColorRgb';
import { largeGutterSize, smallGutterSize } from '../Grid/variables';

export const RawStyledTextButtonBody = styled(StyledButtonBody)`
  border-radius: 4px;
  height: ${props => 36 - (2 * props.$density)}px;
  padding-left: ${smallGutterSize}px;
  padding-right: ${smallGutterSize}px;
  position: relative;

  @media (min-width: 720px) {
    padding-left: ${largeGutterSize}px;
    padding-right: ${largeGutterSize}px;
  }

  ${RawStyledTextButton}:hover & {
    background-color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: 0.08 })};
  }

  ${RawStyledTextButton}:focus & {
    background-color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: 0.16 })};
  }

  ${RawStyledTextButton}:active & {
    background-color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: 0.24 })};
  }
`;

export const StyledTextButtonBody = RawStyledTextButtonBody;
