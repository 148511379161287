// @flow strict

import React, { forwardRef } from 'react';
import PropagatedTransition from '../Transition/PropagatedTransition';
import type { HeroTransitionProps } from './HeroTransitionProps';
import { heroEnterAnimationDuration, heroExitAnimationDuration } from '../../styled-components/Hero/variables';

const timeout = {
  enter: heroEnterAnimationDuration,
  exit: heroExitAnimationDuration,
};

const HeroTransition = forwardRef(
  (props: HeroTransitionProps, ref) => (
    <PropagatedTransition {...props} ref={ref} timeout={timeout} />
  ),
);

export default HeroTransition;
