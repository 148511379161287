// @flow strict-local

import React, { forwardRef } from 'react';
import { StyledMassiveHeroBackgroundBottomImage } from '../../styled-components/MassiveHero/MassiveHeroBackgroundBottomImage';
import type { MassiveHeroBackgroundBottomImageProps } from './MassiveHeroBackgroundBottomImageProps';

const MassiveHeroBackgroundBottomImage = (props: MassiveHeroBackgroundBottomImageProps, ref: mixed) => (
  <StyledMassiveHeroBackgroundBottomImage
    {...props}
    ref={ref}
  />
);

export default forwardRef(MassiveHeroBackgroundBottomImage);
