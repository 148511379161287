// @flow strict

import React, { useContext, useState } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import { StyledTabGroup } from '../../styled-components/Tab/TabGroup';
import TabGroupColorContext from './TabGroupColorContext';
import TabGroupSelectedTabIndexContext from './TabGroupSelectedTabIndexContext';
import TabGroupSetTabBodyContentDimsContext from './TabGroupSetTabBodyContentDimsContext';
import TabGroupTabBodyContentDimsContext from './TabGroupTabBodyContentDimsContext';
import type { TabGroupProps } from './TabGroupProps';
import getContrastColor from '../Background/getContrastColor';

const TabGroup = (props: TabGroupProps) => {
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const { color = getContrastColor(parentBackgroundColor), selectedTabIndex = -1, ...rest } = props;
  const backgroundColor = useContext(BackgroundColorContext);
  const [tabBodyContentDims, setTabBodyContentDims] = useState({});

  return (
    <TabGroupColorContext.Provider value={color}>
      <TabGroupSelectedTabIndexContext.Provider value={selectedTabIndex}>
        <TabGroupSetTabBodyContentDimsContext.Provider value={setTabBodyContentDims}>
          <TabGroupTabBodyContentDimsContext.Provider value={tabBodyContentDims}>
            <StyledTabGroup $backgroundColor={backgroundColor} {...rest} />
          </TabGroupTabBodyContentDimsContext.Provider>
        </TabGroupSetTabBodyContentDimsContext.Provider>
      </TabGroupSelectedTabIndexContext.Provider>
    </TabGroupColorContext.Provider>
  );
};

export default TabGroup;
