// @flow strict

import styled from 'styled-components';

export const RawStyledLogo = styled.div`
  align-items: center;
  display: inline-flex;
  padding-left: 4px;
  padding-right: 4px;

  @media (min-width: 720px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const StyledLogo = RawStyledLogo;
