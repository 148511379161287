// @flow strict-local

import styled from 'styled-components';
import Svg from '../../images/hero_2.svg';

export const RawStyledHeroBackgroundImageSvg2 = styled(Svg)`
  height: 1275.068px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1275.069px;
`;

export const StyledHeroBackgroundImageSvg2 = RawStyledHeroBackgroundImageSvg2;
