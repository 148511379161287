// @flow strict

import React from 'react';
import { StyledHeadline5Text } from '../../styled-components/Headline5/Headline5Text';
import type { Headline5TextProps } from './Headline5TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Headline5Text = (props: Headline5TextProps) => (
  <StyledHeadline5Text {...props} />
);

export default withTypographyText(Headline5Text);
