// @flow strict

import React from 'react';
import { StyledHeadline4 } from '../../styled-components/Headline4/Headline4';
import type { Headline4Props } from './Headline4Props';
import withTypography from '../Typography/withTypography';

const Headline4 = (props: Headline4Props) => (
  <StyledHeadline4 {...props} />
);

export default withTypography(Headline4);
