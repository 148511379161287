// @flow strict-local

import React, { useContext } from 'react';
import ColorContext from './IconColorContext';
import EmphasisContext from './IconEmphasisContext';
import { StyledIconImage } from '../../styled-components/Icon/IconImage';
import type { IconImageProps } from './IconImageProps';

const IconImage = (props: IconImageProps) => (
  <StyledIconImage
    {...props}
    $color={useContext(ColorContext)}
    $emphasis={useContext(EmphasisContext)}
  />
);

export default IconImage;
