// @flow strict

import { rgb } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import type { StyledTabGroupSelectorHeadProps } from './TabGroupSelectorHeadProps';
import getColorRgb from '../Palette/getColorRgb';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const getAnimation = (props: StyledTabGroupSelectorHeadProps) => {
  const { $animationType } = props;

  if ($animationType === 'fadeIn') {
    return css`
      animation-duration: 250ms;
      animation-name: ${fadeIn};
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    `;
  }

  if ($animationType === 'fadeOut') {
    return css`
      animation-duration: 150ms;
      animation-name: ${fadeOut};
      animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    `;
  }

  return undefined;
};

const getTransform = (props: StyledTabGroupSelectorHeadProps) => {
  const { $offset, $width } = props;
  const scaleX = $width;
  const translateX = $offset / scaleX;
  return `scaleX(${scaleX}) translateX(${translateX}px)`;
};

export const RawStyledTabGroupSelectorHead = styled.div`
  ${getAnimation}
  background-color: ${props => rgb(getColorRgb(props.$color))};
  bottom: 0;
  position: absolute;
  top: 0;
  transform: ${getTransform};
  transform-origin: left;
  transition-duration: 350ms;
  transition-property: ${props => (props.$animationType === 'slide' ? 'transform' : undefined)};
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 1px;
`;

export const StyledTabGroupSelectorHead = RawStyledTabGroupSelectorHead;
