// @flow strict-local

import { rgba } from 'polished';
import styled from 'styled-components';
import { standardTimingFunction } from '../Animation/variables';
import getRgba from '../Palette/getRgba';

export const RawStyledIconImage = styled.span`
  color: ${(props) => rgba(getRgba(props.$color, props.$emphasis))};
  contain: layout paint size;
  fill: ${(props) => rgba(getRgba(props.$color, props.$emphasis))};
  height: 100%;
  transition-duration: 300ms;
  transition-property: color fill;
  transition-timing-function: ${standardTimingFunction};
  width: 100%;
`;

export const StyledIconImage = RawStyledIconImage;
