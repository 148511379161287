// @flow strict

import { useMediaLayout } from 'use-media';
import type { BreakpointProps } from './BreakpointProps';

const getStartSize = (size: number) => {
  switch (size) {
    case 0: return null;
    case 1: return 360;
    case 2: return 400;
    case 3: return 480;
    case 4: return 600;
    case 5: return 720;
    case 6: return 840;
    case 7: return 960;
    case 8: return 1024;
    case 9: return 1280;
    case 10: return 1440;
    case 11: return 1600;
    case 12: return 1920;
    default:
      console.error(`Invalid starting breakpoint size “${size}”`);
      return 1920;
  }
};

const getEndSize = (size: number) => {
  switch (size) {
    case 0:
      console.error('Invalid ending breakpoint size “0”');
      return 0;
    case 1: return 359;
    case 2: return 399;
    case 3: return 479;
    case 4: return 599;
    case 5: return 719;
    case 6: return 839;
    case 7: return 959;
    case 8: return 1023;
    case 9: return 1279;
    case 10: return 1439;
    case 11: return 1599;
    case 12: return 1919;
    case Number.POSITIVE_INFINITY:
      return null;
    default:
      console.error(`Invalid ending breakpoint size “${size}”`);
      return null;
  }
};

const Breakpoint = (props: BreakpointProps) => {
  const { children, end = Number.POSITIVE_INFINITY, start = 0 } = props;
  const minWidth = getStartSize(start);
  const maxWidth = getEndSize(end);
  let mediaQuery = '';

  if (minWidth !== null && maxWidth !== null) {
    mediaQuery = `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
  } else if (minWidth !== null) {
    mediaQuery = `(min-width: ${minWidth}px)`;
  } else if (maxWidth !== null) {
    mediaQuery = `(max-width: ${maxWidth}px)`;
  }

  const isVisible = useMediaLayout(mediaQuery);

  return isVisible ? children : null;
};

export default Breakpoint;
