// @flow strict

import React from 'react';
import { StyledSubtitle1 } from '../../styled-components/Subtitle1/Subtitle1';
import type { Subtitle1Props } from './Subtitle1Props';
import withTypography from '../Typography/withTypography';

const Subtitle1 = (props: Subtitle1Props) => (
  <StyledSubtitle1 {...props} />
);

export default withTypography(Subtitle1);
