// @flow strict

import React from 'react';
import { StyledTitleText } from '../../styled-components/Title/TitleText';
import type { TitleTextProps } from './TitleTextProps';
import withTypographyText from '../Typography/withTypographyText';

const TitleText = (props: TitleTextProps) => (
  <StyledTitleText {...props} />
);

export default withTypographyText(TitleText);
