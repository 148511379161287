// @flow strict

import React, { forwardRef, useCallback, useContext, useRef } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import ForegroundColorContext from '../Foreground/ForegroundColorContext';
import ForegroundEmphasisContext from '../Foreground/ForegroundEmphasisContext';
import IconIsDenseContext from '../Icon/IconIsDenseContext';
import IconIsDisabledContext from '../Icon/IconIsDisabledContext';
import { StyledIconButton } from '../../styled-components/IconButton/IconButton';
import { iconButtonInkRippleDuration } from '../../styled-components/IconButton/variables';
import type { IconButtonProps } from './IconButtonProps';
import getContrastColor from '../Background/getContrastColor';
import InkPressesContext from '../Ink/InkPressesContext';
import useInkPresses from '../Ink/useInkPresses';

const IconButton = (props: IconButtonProps, parentRef: mixed) => {
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const parentForegroundColor = useContext(ForegroundColorContext);
  const contrastColor = getContrastColor(parentBackgroundColor);
  const defaultColor = parentForegroundColor ?? contrastColor;
  const defaultEmphasis = 'medium';
  const [inkPresses, handleInkPress] = useInkPresses(iconButtonInkRippleDuration);
  const ref = parentRef ?? useRef();

  const {
    color = defaultColor,
    emphasis = defaultEmphasis,
    isDense = false,
    isDisabled = false,
    isUnclickable = false,
    onClick,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  const handleClick = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    if (!isUnclickable) {
      if (onClick) onClick(e);
      if (e.target) e.target.blur();
      handleInkPress(e, ref.current);
    }
  }, [handleInkPress, isUnclickable, onClick]);

  return (
    <InkPressesContext.Provider value={inkPresses}>
      <ForegroundColorContext.Provider value={color}>
        <ForegroundEmphasisContext.Provider value={emphasis}>
          <IconIsDenseContext.Provider value={isDense}>
            <IconIsDisabledContext.Provider value={isDisabled}>
              <StyledIconButton
                {...rest}
                ref={ref}
                $isDisabled={isDisabled}
                $isUnclickable={isUnclickable}
                $spaceBottom={spaceBottom}
                $spaceLeft={spaceLeft}
                $spaceRight={spaceRight}
                $spaceTop={spaceTop}
                disabled={isDisabled}
                onClick={handleClick}
              />
            </IconIsDisabledContext.Provider>
          </IconIsDenseContext.Provider>
        </ForegroundEmphasisContext.Provider>
      </ForegroundColorContext.Provider>
    </InkPressesContext.Provider>
  );
};

export default forwardRef(IconButton);
