// @flow strict

import React from 'react';
import { StyledHeadline4Text } from '../../styled-components/Headline4/Headline4Text';
import type { Headline4TextProps } from './Headline4TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Headline4Text = (props: Headline4TextProps) => (
  <StyledHeadline4Text {...props} />
);

export default withTypographyText(Headline4Text);
