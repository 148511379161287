// @flow strict-local

import React, { forwardRef } from 'react';
import HeroIsInAGroupContext from './HeroIsInAGroupContext';
import HeroTransitionDirectionContext from './HeroTransitionDirectionContext';
import { StyledHeroGroup } from '../../styled-components/Hero/HeroGroup';
import usePrevious from '../../hooks/usePrevious';
import type { HeroGroupProps } from './HeroGroupProps';

const HeroGroup = (props: HeroGroupProps, ref: mixed) => {
  const { index, offsetBy = null, ...rest } = props;
  const prevIndex = usePrevious(index);
  let transitionDirection = null;

  if (typeof index === 'number') {
    if (typeof prevIndex === 'number') {
      if (index > prevIndex) {
        transitionDirection = 'forwards';
      } else if (index < prevIndex) {
        transitionDirection = 'backwards';
      }
    }
  }

  return (
    <HeroIsInAGroupContext.Provider value>
      <HeroTransitionDirectionContext.Provider value={transitionDirection}>
        <StyledHeroGroup
          {...rest}
          ref={ref}
          $offsetBy={offsetBy}
        />
      </HeroTransitionDirectionContext.Provider>
    </HeroIsInAGroupContext.Provider>
  );
};

export default forwardRef(HeroGroup);
