// @flow strict

import React, { useContext } from 'react';
import BackgroundColorContext from './ToolbarBackgroundColorContext';
import { StyledToolbarBody } from '../../styled-components/Toolbar/ToolbarBody';
import type { ToolbarBodyProps } from './ToolbarBodyProps';

const ToolbarBody = (props: ToolbarBodyProps) => (
  <StyledToolbarBody
    $backgroundColor={useContext(BackgroundColorContext)}
    {...props}
  />
);

export default ToolbarBody;
