// @flow strict-local

import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Body1,
  Body1Text,
  PageContainer,
  Section,
  Text,
  Title,
  TitleText,
} from '../../../../../..';

import MassiveHero from './MassiveHero';

const LandingPageInitialContent = () => (
  <>
    <MassiveHero />
    <Section>
      <PageContainer>
        <Title as="h1" color="primaryDark" emphasis="full" spaceBottom="xLarge">
          <TitleText>
            <FormattedMessage id="LANDING_PAGE_SECONDARY_TITLE" />
          </TitleText>
        </Title>
        <Body1 lineLength="medium" position="center" spaceTop="medium">
          <Body1Text>
            <FormattedMessage id="LANDING_PAGE_SECONDARY_BODY_1" />
          </Body1Text>
        </Body1>
        <Body1 lineLength="medium" position="center" spaceTop="large">
          <Body1Text>
            <FormattedMessage
              id="LANDING_PAGE_SECONDARY_BODY_2"
              values={{
                cta: (
                  <Text emphasis="high">
                    <FormattedMessage id="LANDING_PAGE_SECONDARY_BODY_2_CTA" />
                  </Text>
                ),
              }}
            />
          </Body1Text>
        </Body1>
      </PageContainer>
    </Section>
  </>
);

export default memo(LandingPageInitialContent);
