// @flow strict-local

import React, { forwardRef } from 'react';
import { rgb } from 'polished';
import styled, { type StyledComponent } from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';
import getSpaceSize from '../Spacer/getSpaceSize';
import { RawStyledSpacer } from '../Spacer/Spacer';
import getScrollBarsStyle from '../ScrollBars/getScrollBarsStyle';

export const RawStyledSection: StyledComponent<StyledSpacer, mixed, HTMLElement> = styled(RawStyledSpacer)`
  ${getScrollBarsStyle}
  background-color: ${(props) => (props.$backgroundColor ? rgb(getColorRgb(props.$backgroundColor)) : undefined)};
  contain: ${props => (props.$canScroll ? undefined : 'layout')};
  overflow: ${props => (props.$canScroll ? 'auto' : undefined)};
  padding-bottom: ${props => (props.$mobileInnerSpaceBottom == null ? undefined : `${getSpaceSize(props.$mobileInnerSpaceBottom)}px`)};
  padding-top: ${props => (props.$mobileInnerSpaceTop == null ? undefined : `${getSpaceSize(props.$mobileInnerSpaceTop)}px`)};
  pointer-events: auto;

  @media (min-width: 720px) {
    padding-bottom: ${props => (props.$desktopInnerSpaceBottom == null ? undefined : `${getSpaceSize(props.$desktopInnerSpaceBottom)}px`)};
    padding-top: ${props => (props.$desktopInnerSpaceTop == null ? undefined : `${getSpaceSize(props.$desktopInnerSpaceTop)}px`)};
  }
`;

export const StyledSection = forwardRef(
  (props: StyledSectionProps, ref: mixed) => {
    const { as = 'section', ...rest } = props;

    return (
      <RawStyledSection {...rest} ref={ref} as={as} />
    );
  },
);
