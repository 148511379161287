// @flow strict

import styled from 'styled-components';
import { RawStyledSpacer } from '../Spacer/Spacer';

export const RawStyledLayout = styled(RawStyledSpacer)`
  align-items: ${props => props.$alignItems};
  contain: layout;
  display: ${props => (props.display == null ? 'flex' : props.display)};
  flex-direction: ${props => props.$flexDirection};
  flex-grow: 1;
  height: ${props => props.$height};
  justify-content: ${props => props.$justifyContent};
`;

export const StyledLayout = RawStyledLayout;
