// @flow strict-local

import styled from 'styled-components';
import Svg from '../../images/hero_3.svg';

export const RawStyledHeroBackgroundImageSvg3 = styled(Svg)`
  height: 927.935px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1086.261px;
`;

export const StyledHeroBackgroundImageSvg3 = RawStyledHeroBackgroundImageSvg3;
