// @flow strict

import styled from 'styled-components';

export const RawStyledButtonBody = styled.div`
  display: inline-block;
  height: 36px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const StyledButtonBody = RawStyledButtonBody;
