// @flow strict-local

import React from 'react';
import { StyledHeroBackgroundImageSvg1 } from '../../styled-components/Hero/HeroBackgroundImageSvg1';

const HeroBackgroundImageSvg1 = () => (
  <StyledHeroBackgroundImageSvg1 />
);

export default HeroBackgroundImageSvg1;
