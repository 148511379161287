// @flow strict

import React, { Fragment, lazy, Suspense } from 'react';

const MissingPage = lazy(() => import('./MissingPage'));

const DeferredMissingPage = () => (
  <Suspense fallback={Fragment}>
    <MissingPage />
  </Suspense>
);

export default DeferredMissingPage;
