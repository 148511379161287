// @flow strict-local

import { rgb } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledMassiveHeroBackgroundTopImageSvg = styled.div`
  bottom: 0;
  fill: ${props => rgb(getColorRgb(props.$color))};
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledMassiveHeroBackgroundTopImageSvg = RawStyledMassiveHeroBackgroundTopImageSvg;
