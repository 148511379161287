// @flow strict

import styled from 'styled-components';

export const RawStyledToolbar = styled.div`
  height: ${(props) => (props.$isExtended ? 128 : 64) - (4 * props.$density)}px;
  position: ${(props) => (props.$isFixed ? 'sticky' : 'relative')};
  top: 0;
  width: 100%;
  z-index: ${(props) => (props.$isFixed ? 2 : 1)};
`;

export const StyledToolbar = RawStyledToolbar;
