// @flow strict

export const xxxSmallSpaceSize = 4;
export const xxSmallSpaceSize = 8;
export const xSmallSpaceSize = 12;
export const smallSpaceSize = 16;
export const mediumSpaceSize = 24;
export const largeSpaceSize = 32;
export const xLargeSpaceSize = 44;
export const xxLargeSpaceSize = 56;
export const xxxLargeSpaceSize = 72;
