// @flow strict

import React, { useContext } from 'react';
import ColorContext from './InkColorContext';
import DurationContext from './InkRippleDurationContext';
import { StyledInkRipple } from '../../styled-components/Ink/InkRipple';
import type { InkRippleProps } from './InkRippleProps';

const InkRipple = (props: InkRippleProps) => {
  const defaultColor = useContext(ColorContext);
  const defaultDuration = useContext(DurationContext);

  const {
    color = defaultColor,
    duration = defaultDuration,
    x,
    y,
    ...rest
  } = props;

  return (
    <StyledInkRipple
      $color={color}
      $duration={duration}
      $x={x}
      $y={y}
      {...rest}
    />
  );
};

export default InkRipple;
