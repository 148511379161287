// @flow strict

import styled from 'styled-components';
import { StyledTypography } from '../Typography/Typography';

export const RawStyledSubtitle2 = styled(StyledTypography)`
  margin-bottom: ${(props) => (props.$hasSpaceBottom ? '16px' : undefined)};
  margin-top: ${(props) => (props.$hasSpaceTop ? '16px' : undefined)};
`;

export const StyledSubtitle2 = RawStyledSubtitle2;
