// @flow strict

import React from 'react';
import { StyledHeadline6 } from '../../styled-components/Headline6/Headline6';
import type { Headline6Props } from './Headline6Props';
import withTypography from '../Typography/withTypography';

const Headline6 = (props: Headline6Props) => (
  <StyledHeadline6 {...props} />
);

export default withTypography(Headline6);
