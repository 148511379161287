// @flow strict

import styled, { type StyledComponent } from 'styled-components';
import { StyledSpacer } from '../Spacer/Spacer';

export const RawStyledGrid: StyledComponent<StyledSpacer, mixed, HTMLElement> = styled(StyledSpacer)`
  align-items: ${props => props.$cellAlignment};
  contain: layout;
  display: grid;
  grid-template-columns: repeat(${props => (props.$shouldAutoFit ? 'auto-fit, minmax(0, 1fr)' : '8, 1fr')});
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(${props => (props.$shouldAutoFit ? 'auto-fit, minmax(0, 1fr)' : '16, 1fr')});
  }

  @media (min-width: 840px) {
    grid-template-columns: repeat(${props => (props.$shouldAutoFit ? 'auto-fit, minmax(0, 1fr)' : '24, 1fr')});
  }
`;

export const StyledGrid = RawStyledGrid;
