// @flow strict

import React from 'react';
import type { LayoutProps } from './LayoutProps';
import { StyledLayout } from '../../styled-components/Layout/Layout';

const Layout = (props: LayoutProps) => {
  const { direction = 'horizontal' } = props;
  const defaultFlexDirection = direction === 'vertical' ? 'column' : 'row';
  const defaultHeight = direction === 'vertical' ? '100%' : null;

  const {
    alignItems,
    flexDirection = defaultFlexDirection,
    height = defaultHeight,
    justifyContent,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  return (
    <StyledLayout
      $alignItems={alignItems}
      $flexDirection={flexDirection}
      $height={height}
      $justifyContent={justifyContent}
      $spaceBottom={spaceBottom}
      $spaceLeft={spaceLeft}
      $spaceRight={spaceRight}
      $spaceTop={spaceTop}
      {...rest}
    />
  );
};

export default Layout;
