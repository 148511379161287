// @flow strict-local

import styled from 'styled-components';
import { denseIconSize, denseIconPadding, regularIconSize, regularIconPadding } from './variables';

export const RawStyledIcon = styled.i`
  contain: layout paint size;
  display: inline-block;
  height: ${props => (props.$isDense ? denseIconSize : regularIconSize)}px;
  padding: ${props => (props.$isDense ? denseIconPadding : regularIconPadding)}px;
  width: ${props => (props.$isDense ? denseIconSize : regularIconSize)}px;
`;

export const StyledIcon = RawStyledIcon;
