// @flow strict

import type { Color } from '../../styled-components/Palette/Color';

export default (backgroundColor: Color) => {
  switch (backgroundColor) {
    case 'dark': return 'xLight';
    case 'darkPrimary': return 'xLight';
    case 'primary': return 'xLight';
    case 'primaryDark': return 'xLight';
    case 'secondary': return 'xLight';
    case 'tertiary': return 'xLight';
    case 'xDark': return 'xLight';
    default: return 'xDark';
  }
};
