// @flow strict

import { useEffect } from 'react';
import { useLocation } from 'react-router';

type Ref = {| current?: void | HTMLElement |};

const useRestoreScroll = (ref: Ref) => {
  const { pathname } = useLocation();
  const { current: el } = ref;

  useEffect(() => {
    if (el) {
      if (el.scrollTo === undefined) {
        el.scrollTop = 0;
      } else {
        el.scroll(0, 0);
      }
    }
  }, [el, pathname]);
};

export default useRestoreScroll;
