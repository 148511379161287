// @flow strict-local

import { rgba } from 'polished';
import styled from 'styled-components';
import { standardTimingFunction } from '../Animation/variables';
import { RawStyledIconButton } from './IconButton';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledIconButtonBody = styled.span`
  border-radius: 50%;
  contain: layout;
  position: relative;
  z-index: 1;

  &::before {
    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0.5);
    transition-duration: 75ms;
    transition-property: background-color, transform;
    transition-timing-function: ${standardTimingFunction};
  }

  ${RawStyledIconButton}:hover &::before {
    background-color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: 0.08 })};
    transform: scale(1);
  }

  ${RawStyledIconButton}:focus &::before {
    background-color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: 0.16 })};
    transform: scale(1);
  }

  ${RawStyledIconButton}:active &::before {
    background-color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: 0.24 })};
    transform: scale(1);
  }
`;

export const StyledIconButtonBody = RawStyledIconButtonBody;
