// @flow strict

import { createGlobalStyle } from 'styled-components';

const StyledCodeMirrorShowHint = createGlobalStyle`
  .CodeMirror-hints {
    background: white;
    border-radius: 3px;
    border: 1px solid silver;
    box-shadow: 2px 3px 5px rgba(0,0,0,.2);
    font-family: monospace;
    font-size: 90%;
    list-style: none;
    margin: 0;
    max-height: 20em;
    overflow-y: auto;
    overflow: hidden;
    padding: 2px;
    position: absolute;
    z-index: 10;
  }

  .CodeMirror-hint {
    border-radius: 2px;
    color: black;
    cursor: pointer;
    margin: 0;
    padding: 0 4px;
    white-space: pre;
  }

  li.CodeMirror-hint-active {
    background: #08f;
    color: white;
  }
`;

export default StyledCodeMirrorShowHint;
