// @flow strict

import React, { forwardRef } from 'react';
import { StyledPageContent } from '../../styled-components/Page/PageContent';
import type { PageContentProps } from './PageContentProps';

const PageContent = forwardRef(
  (props: PageContentProps, ref) => (
    <StyledPageContent ref={ref} {...props} />
  ),
);

export default PageContent;
