// @flow strict

import { useEffect, useRef } from 'react';

const usePrevious = (value: mixed) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current !== value) {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
};

export default usePrevious;
