// @flow strict

export const darkColorRgb = { blue: 255, green: 255, red: 255 };
export const darkPrimaryColorRgb = { blue: 53, green: 39, red: 40 };
export const highEmphasisOpacity = 0.87;
export const lightColorRgb = { blue: 241, green: 241, red: 241 };
export const lowEmphasisOpacity = 0.38;
export const mediumEmphasisOpacity = 0.6;
export const negativeColorRgb = { blue: 12, green: 0, red: 242 };
export const positiveColorRgb = { blue: 72, green: 128, red: 64 };
export const primaryColorRgb = { blue: 224, green: 163, red: 0 };
export const primaryDarkColorRgb = { blue: 157, green: 115, red: 0 };
export const quaternaryColorRgb = { blue: 98, green: 140, red: 255 };
export const secondaryColorRgb = { blue: 119, green: 105, red: 255 };
export const slightlyNegativeColorRgb = { blue: 12, green: 129, red: 242 };
export const tertiaryColorRgb = { blue: 112, green: 179, red: 100 };
export const vibrantSecondaryRgb = { blue: 0, green: 40, red: 255 };
export const vibrantTertiaryRgb = { blue: 61, green: 224, red: 0 };
export const xDarkColorRgb = { blue: 0, green: 0, red: 0 };
export const xLightColorRgb = { blue: 255, green: 255, red: 255 };
