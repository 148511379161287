// @flow strict

import React, { useContext } from 'react';
import TextButtonSizeContext from './TextButtonSizeContext';
import TextButtonDensityContext from './TextButtonDensityContext';
import TextColorContext from './TextButtonColorContext';
import { StyledTextButtonBodyText } from '../../styled-components/TextButton/TextButtonBodyText';
import type { TextButtonBodyTextProps } from './TextButtonProps';

const TextButtonBodyText = (props: TextButtonBodyTextProps) => (
  <StyledTextButtonBodyText
    $color={useContext(TextColorContext)}
    $density={useContext(TextButtonDensityContext)}
    $size={useContext(TextButtonSizeContext)}
    {...props}
  />
);

export default TextButtonBodyText;
