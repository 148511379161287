// @flow strict

import type { Emphasis } from './Emphasis';

import {
  highEmphasisOpacity,
  lowEmphasisOpacity,
  mediumEmphasisOpacity,
} from './variables';

export default (emphasis: Emphasis) => {
  switch (emphasis) {
    case 'full':
      return 1;
    case 'high':
      return highEmphasisOpacity;
    case 'low':
      return lowEmphasisOpacity;
    case 'medium':
      return mediumEmphasisOpacity;
    case 'none':
      return 0;
    default:
      throw new Error(`Unknown emphasis “${emphasis}”`);
  }
};
