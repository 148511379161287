// @flow strict

import styled from 'styled-components';

export const RawStyledMassiveHeroContentBody = styled.div`
  @media (min-height: 720px) {
    padding-top: 12px;
  }

  @media (min-height: 840px) {
    padding-top: 28px;
  }

  @media (min-height: 960px) and (min-width: 960px) {
    padding-top: 96px;
  }
`;

export const StyledMassiveHeroContentBody = RawStyledMassiveHeroContentBody;
