// @flow strict

import React, { forwardRef } from 'react';
import type { PageContainerProps } from './PageContainerProps';
import { StyledPageContainer } from '../../styled-components/Page/PageContainer';

const PageContainer = (props: PageContainerProps, ref: mixed) => {
  const {
    isFullWidth = false,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  return (
    <StyledPageContainer
      {...rest}
      ref={ref}
      $isFullWidth={isFullWidth}
      $spaceBottom={spaceBottom}
      $spaceLeft={spaceLeft}
      $spaceRight={spaceRight}
      $spaceTop={spaceTop}
    />
  );
};

export default forwardRef(PageContainer);
