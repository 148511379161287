// @flow strict

import styled from 'styled-components';

export const RawStyledSvg = styled.svg`
  contain: layout paint size;
  height: 100%;
  width: 100%;
`;

export const StyledSvg = RawStyledSvg;
