// @flow strict

import React from 'react';
import { StyledBody2 } from '../../styled-components/Body2/Body2';
import type { Body2Props } from './Body2Props';
import withTypography from '../Typography/withTypography';

const Body2 = (props: Body2Props) => (
  <StyledBody2 {...props} />
);

export default withTypography(Body2);
