// @flow strict

import React, { type Component, useContext } from 'react';
import AlignmentContext from './TypographyAlignmentContext';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import ForegroundColorContext from '../Foreground/ForegroundColorContext';
import ForegroundEmphasisContext from '../Foreground/ForegroundEmphasisContext';
import ForegroundIsCodeContext from '../Foreground/ForegroundIsCodeContext';
import TypographyIsItalicContext from './TypographyIsItalicContext';
import TypographyWeightContext from './TypographyWeightContext';
import type { TypographyProps } from './TypographyProps';
import getContrastColor from '../Background/getContrastColor';
import type { StyledTypographyProps } from '../../styled-components/Typography/TypographyProps';

const withTypography = (
  (StyledTypography: Component<StyledTypographyProps>) => (
    (props: TypographyProps) => {
      const fallbackEmphasis = 'medium';
      const fallbackIsCode = false;
      const parentBackgroundColor = useContext(BackgroundColorContext);
      const parentForegroundColor = useContext(ForegroundColorContext);
      const parentForegroundEmphasis = useContext(ForegroundEmphasisContext);
      const parentAlignment = useContext(AlignmentContext);
      const parentIsCode = useContext(ForegroundIsCodeContext);
      const contrastColor = getContrastColor(parentBackgroundColor);
      const defaultAlignment = parentAlignment;
      const defaultColor = parentForegroundColor ?? contrastColor;
      const defaultEmphasis = parentForegroundEmphasis ?? fallbackEmphasis;
      const defaultIsCode = parentIsCode ?? fallbackIsCode;

      const {
        alignment = defaultAlignment,
        color = defaultColor,
        emphasis = defaultEmphasis,
        isCode = defaultIsCode,
        isItalic,
        lineLength,
        position,
        spaceBottom,
        spaceLeft,
        spaceRight,
        spaceTop,
        weight,
        ...rest
      } = props;

      return (
        <AlignmentContext.Provider value={alignment}>
          <ForegroundColorContext.Provider value={color}>
            <ForegroundEmphasisContext.Provider value={emphasis}>
              <ForegroundIsCodeContext.Provider value={isCode}>
                <TypographyIsItalicContext.Provider value={isItalic}>
                  <TypographyWeightContext.Provider value={weight}>
                    <StyledTypography
                      {...rest}
                      $lineLength={lineLength}
                      $position={position}
                      $spaceBottom={spaceBottom}
                      $spaceLeft={spaceLeft}
                      $spaceRight={spaceRight}
                      $spaceTop={spaceTop}
                    />
                  </TypographyWeightContext.Provider>
                </TypographyIsItalicContext.Provider>
              </ForegroundIsCodeContext.Provider>
            </ForegroundEmphasisContext.Provider>
          </ForegroundColorContext.Provider>
        </AlignmentContext.Provider>
      );
    }
  )
);

export default withTypography;
