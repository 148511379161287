// @flow strict

import React, { useContext } from 'react';
import TabGroupTabsAreEqualWidthContext from './TabGroupTabsAreEqualWidthContext';
import { StyledTabGroupTab } from '../../styled-components/Tab/TabGroupTab';

const TabGroupTab = (props: TabGroupTagsProps) => (
  <StyledTabGroupTab
    $isEqualWidth={useContext(TabGroupTabsAreEqualWidthContext)}
    {...props}
  />
);

export default TabGroupTab;
