// @flow strict

import React from 'react';
import { StyledTitle } from '../../styled-components/Title/Title';
import type { TitleProps } from './TitleProps';
import withTypography from '../Typography/withTypography';

const Title = (props: TitleProps) => (
  <StyledTitle {...props} />
);

export default withTypography(Title);
