// // @flow strict

import React, { forwardRef, useContext } from 'react';
import ForegroundColorContext from '../Foreground/ForegroundColorContext';
import ForegroundEmphasisContext from '../Foreground/ForegroundEmphasisContext';
import { StyledText } from '../../styled-components/Text/Text';
import type { TextProps } from './TextProps';

const Text = (props: TextProps, ref: mixed) => {
  const parentColor = useContext(ForegroundColorContext);
  const parentEmphasis = useContext(ForegroundEmphasisContext);

  const {
    color = parentColor,
    emphasis = parentEmphasis,
    isBlock = false,
    isCode,
    isItalic,
    weight,
    ...rest
  } = props;

  return (
    <StyledText
      {...rest}
      ref={ref}
      $color={color}
      $emphasis={emphasis}
      $isBlock={isBlock}
      $isCode={isCode}
      $isItalic={isItalic}
      $weight={weight}
    />
  );
};

export default forwardRef(Text);
