// @flow strict

import React, { useEffect, useRef, useState } from 'react';
import { StyledTabGroupSelector } from '../../styled-components/Tab/TabGroupSelector';
import TabGroupSelectorTrackDimsContext from './TabGroupSelectorTrackDimsContext';
import type { TabGroupSelectorProps } from './TabGroupSelectorProps';

const TabGroupSelector = (props: TabGroupSelectorProps) => {
  const { showTrack = false, ...rest } = props;
  const [trackDims, setTrackDims] = useState({ x: null });
  const ref = useRef();

  useEffect(() => {
    const el = ref.current;

    if (el) {
      const { x } = el.getBoundingClientRect();

      if (trackDims.x !== x) {
        setTrackDims({ x });
      }
    }
  });

  return (
    <TabGroupSelectorTrackDimsContext.Provider value={trackDims}>
      <StyledTabGroupSelector
        ref={ref}
        $showTrack={showTrack}
        {...rest}
      />
    </TabGroupSelectorTrackDimsContext.Provider>
  );
};

export default TabGroupSelector;
