// @flow strict

import styled from 'styled-components';

export const RawStyledPageContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
`;

export const StyledPageContent = RawStyledPageContent;
