// @flow strict-local

import { rgba } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';
import getEmphasisOpacity from '../Palette/getEmphasisOpacity';

export const RawStyledTypographyText = styled.span`
  color: ${(props) => rgba({ ...getColorRgb(props.$color), alpha: getEmphasisOpacity(props.$emphasis) })};
  contain: layout;
  font-family: ${(props) => (props.$isCode ? 'Inconsolata' : undefined)};
  font-weight: ${props => props.$weight};
  font-style: ${props => (props.$isItalic ? 'italic' : undefined)};
  pointer-events: auto;
  text-align: ${(props) => props.$alignment};
  user-select: text;
`;

export const StyledTypographyText = RawStyledTypographyText;
