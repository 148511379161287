// @flow strict

import React from 'react';
import Svg from '@mdi/svg/svg/github.svg';
import { StyledSvg } from '../../styled-components/Svg/Svg';
import type { SvgProps } from './SvgProps';

export default (props: SvgProps) => (
  <StyledSvg as={Svg} {...props} />
);
