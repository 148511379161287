// @flow strict

export const massiveHeroEnterAnimationDuration = 1100;
export const massiveHeroExitAnimationDuration = 450;

export const maxHeight = 1002;
export const maxWidth = 3460;
export const maxOffset = 1000;
export const widthRatio = maxWidth / maxHeight;
export const offsetRatio = maxOffset / maxWidth;
export const minHeight = 512;
export const minWidth = widthRatio * minHeight;
export const relHeight = 0.7;
export const relWidth = relHeight * widthRatio;

export const massiveHeroHeightRatio = 0.7;
export const massiveHeroMaximumHeight = 1002;
export const massiveHeroMinimumHeight = 550;

export const imageMaximumHeight = 1002;
export const imageMaximumWidth = 3460;
export const imageWidthRatio = imageMaximumWidth / imageMaximumHeight;
export const imageMinimumHeight = massiveHeroMinimumHeight;
export const imageMinimumWidth = imageMinimumHeight * imageWidthRatio;
