// @flow strict

import styled from 'styled-components';
import { largeGutterSize, smallGutterSize } from '../Grid/variables';
import { StyledSpacer } from '../Spacer/Spacer';

export const RawStyledTypography = styled(StyledSpacer)`
  contain: layout;
  margin-left: ${props => (['center', 'end', 'right'].includes(props.$position) ? 'auto' : undefined)};
  margin-right: ${props => (['center'].includes(props.$position) ? 'auto' : undefined)};
  padding-left: ${smallGutterSize}px;
  padding-right: ${smallGutterSize}px;

  @media (min-width: 720px) {
    padding-left: ${largeGutterSize}px;
    padding-right: ${largeGutterSize}px;
  }
`;

export const StyledTypography = RawStyledTypography;
