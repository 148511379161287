// @flow strict

import React from 'react';
import { StyledBody1 } from '../../styled-components/Body1/Body1';
import type { Body1Props } from './Body1Props';
import withTypography from '../Typography/withTypography';

const Body1 = (props: Body1Props) => (
  <StyledBody1 {...props} />
);

export default withTypography(Body1);
