// @flow strict

import React, { Fragment, lazy, Suspense } from 'react';

const PricingPage = lazy(() => import('./PricingPage'));

const DeferredPricingPage = () => (
  <Suspense fallback={Fragment}>
    <PricingPage />
  </Suspense>
);

export default DeferredPricingPage;
