// @flow strict

import React from 'react';
import { StyledBody2Text } from '../../styled-components/Body2/Body2Text';
import type { Body2TextProps } from './Body2TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Body2Text = (props: Body2TextProps) => (
  <StyledBody2Text {...props} />
);

export default withTypographyText(Body2Text);
