// @flow strict

export const ABOUT = 'About';
export const ABOUT_COMPANY = 'About Genesant';
export const ABOUT_PAGE_CONTACT = 'For business development and other inquiries, {contact}.';
export const ABOUT_PAGE_DESCRIPTION_1 = 'We are engineers and data scientists that have worked continuously on providing search, recognition, recommendation, assessment and data logging services in the area of personal health management since 1999.';
export const ABOUT_PAGE_DESCRIPTION_2 = 'Along the way, we have amassed proprietary data and developed unique insights by drawing from the billions of customer interactions that we have managed. Our technology has powered services offered by industry-leading media companies, manufacturers and institutional service providers.';
export const ABOUT_PAGE_HERO_SUBTITLE = 'Personal health data and analytics is our speciality';
export const ABOUT_PAGE_HERO_TITLE = 'Two decades of innovation';
export const ABOUT_PAGE_PAGE_TITLE = 'About Us';
export const ABOUT_PAGE_PRIMARY_SUBTITLE = 'Something snazzy about the developer community we support';
export const ABOUT_PAGE_PRIMARY_TITLE = 'About Genesant';
export const ABOUT_US = 'About us';
export const ADD_TO_YOUR_SHOPPING_CART = 'Nam sed dolor eu tortor consectetur';
export const AUTHENTICATION = 'Authentication';
export const CALORIE = '{display, select, short {cal} other {calorie}}';
export const CANCEL = 'Cancel';
export const CARBOHYDRATE = '{display, select, short {carb} other {carbohydrate}}';
export const CARBOHYDRATES = '{display, select, short {carbs} other {carbohydrates}}';
export const CEO_AND_COFOUNDER = 'CEO and Co-Founder';
export const CHAIRMAN_AND_COFOUNDER = 'Chairman and Co-Founder';
export const CODE = 'Code';
export const CODE_SAMPLES = 'Code samples';
export const COMPANY = 'Company';
export const CONSOLE = 'Console';
export const CONTACT_US = 'Contact us';
export const COPYRIGHT_YEAR_AND_COMPANY = '© {year} Genesant Technologies, Inc.';
export const COST = 'Cost';
export const COUNTRY_FULL_NAME_CA = 'Canada';
export const COUNTRY_FULL_NAME_DE = 'Germany';
export const COUNTRY_FULL_NAME_ES = 'Spain';
export const COUNTRY_FULL_NAME_FR = 'France';
export const COUNTRY_FULL_NAME_GB = 'United Kingdom';
export const COUNTRY_FULL_NAME_IT = 'Italy';
export const COUNTRY_FULL_NAME_US = 'United States';
export const CTO = 'CTO';
export const DESCRIPTION = 'Description';
export const DEVELOPER = 'Developer';
export const DEVELOPER_PAGE_PAGE_TITLE = 'Developer';
export const DOCUMENTATION = 'Documentation';
export const DOCUMENTATION_PAGE_HERO_SUBTITLE = 'Browse our API reference material and sample code';
export const DOCUMENTATION_PAGE_HERO_TITLE = 'Documentation';
export const DOCUMENTATION_PAGE_PAGE_TITLE = 'Documentation';
export const EMAIL_ADDRESS = 'Email Address';
export const ENUMS = 'Enums';
export const ERROR_TYPE = 'Error Type';
export const EXAMPLES = 'Examples';
export const FAT = 'fat';
export const FOR_EXAMPLE = 'Try “{value}”';
export const FORMATTED_QUANTITY = `{magnitudeNode}{unit, select,
  BAG {{magnitude, plural,
    one { bag}
    other { bags}
  }}
  CALORIE {{magnitude, plural,
    one {{unitDisplay, select,
      narrow {cal}
      short { cal}
      long { calorie}
      other { calorie}
    }}
    other {{unitDisplay, select,
      narrow {cal}
      short { cals}
      long { calories}
      other { calories}
    }}
  }}
  GRAM {{magnitude, plural,
    one {{unitDisplay, select,
      narrow {g}
      short { g}
      long { gram}
      other {g}
    }}
    other {{unitDisplay, select,
      narrow {g}
      short { g}
      long { grams}
      other {g}
    }}
  }}
  IU {{magnitude, plural,
    one {{unitDisplay, select,
      narrow {IU}
      short { IU}
      long { IU}
      other { IU}
    }}
    other {{unitDisplay, select,
      narrow {IU}
      short { IU}
      long { IU}
      other { IU}
    }}
  }}
  MICROGRAM {{magnitude, plural,
    one {{unitDisplay, select,
      narrow {mcg}
      short { mcg}
      long { microgram}
      other {mcg}
    }}
    other {{unitDisplay, select,
      narrow {mcg}
      short { mcg}
      long { mcgrams}
      other {mcg}
    }}
  }}
  MILLIGRAM {{magnitude, plural,
    one {{unitDisplay, select,
      narrow {mg}
      short { mg}
      long { milligram}
      other {mg}
    }}
    other {{unitDisplay, select,
      narrow {mg}
      short { mg}
      long { milligrams}
      other {mg}
    }}
  }}
  OUNCE {{magnitude, plural,
    one {{unitDisplay, select,
      narrow {oz}
      short { oz}
      long { ounce}
      other {oz}
    }}
    other {{unitDisplay, select,
      narrow {oz}
      short { oz}
      long { ounces}
      other {oz}
    }}
  }}
}{hasLabel, select,
  true {{hasOf, select,
    true { of {label}}
    other { {label}}
  }}
  other {}
}`;
export const FORMATTED_QUANTITY_LABEL = `{label, select,
  CARBOHYDRATES {{display, select,
    short {carbs}
    long {carbohydrates}
    other {carbs}
  }}
  FAT {fat}
  PROTEIN {{display, select,
    short {prot}
    long {protein}
    other {protein}
  }}
}`;
export const GENESANT_REST_API = 'REST API';
export const GETTING_STARTED = 'Getting started';
export const GRAPHQL = 'GraphQL';
export const GRAPHQL_API = 'GraphQL API';
export const GRAPHQL_API_DOCUMENTATION_PAGE_PAGE_TITLE = 'GraphQL Documentation';
export const GRAPHQL_KEYWORD = 'GraphQL';
export const GRAPHQL_KEYWORDS_SUBSCRIPTION = 'Subscription';
export const GRAPHQL_KEYWORDS_SUBSCRIPTIONS = 'Subscriptions';
export const GRAPHQL_KEYWORD_ARGUMENTS = 'Arguments';
export const GRAPHQL_KEYWORD_BELONGS_TO = 'Belongs to';
export const GRAPHQL_KEYWORD_DIRECTIVE = 'Directive';
export const GRAPHQL_KEYWORD_DIRECTIVES = 'Directives';
export const GRAPHQL_KEYWORD_ENUM = 'Enum';
export const GRAPHQL_KEYWORD_ENUMS = 'Enums';
export const GRAPHQL_KEYWORD_ENUM_VALUE = 'Enum value';
export const GRAPHQL_KEYWORD_ENUM_VALUES = 'Enum values';
export const GRAPHQL_KEYWORD_FIELD = 'Field';
export const GRAPHQL_KEYWORD_FIELDS = 'Fields';
export const GRAPHQL_KEYWORD_GRAPHQL_FIELD = 'GraphQL Field';
export const GRAPHQL_KEYWORD_IMPLEMENTED_BY = 'Implemented by';
export const GRAPHQL_KEYWORD_IMPLEMENTS = 'Implements';
export const GRAPHQL_KEYWORD_INPUT_FIELDS = 'Input fields';
export const GRAPHQL_KEYWORD_INPUT_OBJECT = 'Input object';
export const GRAPHQL_KEYWORD_INPUT_OBJECTS = 'Input objects';
export const GRAPHQL_KEYWORD_INPUT_OBJECT_FIELD = 'Input field';
export const GRAPHQL_KEYWORD_INPUT_OBJECT_FIELDS = 'Input fields';
export const GRAPHQL_KEYWORD_INPUT_PROP = 'Input field';
export const GRAPHQL_KEYWORD_INTERFACE = 'Interface';
export const GRAPHQL_KEYWORD_INTERFACES = 'Interfaces';
export const GRAPHQL_KEYWORD_INTERFACE_FIELD = 'Interface field';
export const GRAPHQL_KEYWORD_MUTATION = 'Mutation';
export const GRAPHQL_KEYWORD_MUTATIONS = 'Mutations';
export const GRAPHQL_KEYWORD_OBJECT = 'Object';
export const GRAPHQL_KEYWORD_OBJECTS = 'Objects';
export const GRAPHQL_KEYWORD_OBJECT_FIELD = 'Object field';
export const GRAPHQL_KEYWORD_POSSIBLE_TYPES = 'Possible types';
export const GRAPHQL_KEYWORD_QUERIES = 'Queries';
export const GRAPHQL_KEYWORD_QUERY = 'Query';
export const GRAPHQL_KEYWORD_REQUIRED_FIELDS = 'Required fields';
export const GRAPHQL_KEYWORD_RESPONSE = 'Response';
export const GRAPHQL_KEYWORD_RETURN_TYPE = 'Return type';
export const GRAPHQL_KEYWORD_SCALAR = 'Scalar';
export const GRAPHQL_KEYWORD_SCALARS = 'Scalars';
export const GRAPHQL_KEYWORD_SCHEMA = 'Schema';
export const GRAPHQL_KEYWORD_TYPEDEF = 'Typedef';
export const GRAPHQL_KEYWORD_UNION = 'Union';
export const GRAPHQL_KEYWORD_UNIONS = 'Unions';
export const GRAPHQL_PRICING_TABLE_FOOTNOTE = '*By default all other GraphQL fields {defaultCost, plural, =0 {are free} one {cost # credit} other {cost # credits}}';
export const GRAPHQL_TYPE_DOCUMENTATION_PAGE_PAGE_TITLE = '{typeName} | GraphQL';
export const GRAPHQL_TYPE_PROP_DOCUMENTATION_PAGE_PAGE_TITLE = '{typePropName} | {typeName}';
export const HIGH_PRIORITY_SUPPORT = 'High priority support';
export const HIGH_VOLUME_PRICING = 'High volume pricing';
export const INFORMATION = 'Information';
export const INTERFACES = 'Interfaces';
export const LANDING_PAGE_API_INTRODUCTION_BODY_1 = 'Access our high-level Recognition API wth your choice of REST or GraphQL.';
export const LANDING_PAGE_API_INTRODUCTION_BODY_2 = 'Our low-level services are accessible from our GraphQL API.';
export const LANDING_PAGE_API_INTRODUCTION_BODY_3 = '{link} to access our AI toolkit for identifying and annotating spoken, conversational descriptions of over 250,000 foods.';
export const LANDING_PAGE_API_INTRODUCTION_BODY_3_LINK = 'Request an API key';
export const LANDING_PAGE_API_INTRODUCTION_SUBTITLE = 'We’ve built a pipeline of cutting-edge neural networks that apply {advancements} to deliver state of the art performance in natural language understanding.';
export const LANDING_PAGE_API_INTRODUCTION_SUBTITLE_ADVANCEMENTS = 'the latest advancements in artificial intelligence';
export const LANDING_PAGE_API_INTRODUCTION_TITLE = 'An API for everything';
export const LANDING_PAGE_MATCHER_BODY = 'The Matcher is an extreme multiclass classifier that uses a TCN and BERT-based transformer network to identify foods from conversational input.';
export const LANDING_PAGE_MATCHER_TITLE = 'Matcher';
export const LANDING_PAGE_PRIMARY_SUBTITLE = 'Find structure and meaning in casual descriptions of food';
export const LANDING_PAGE_PRIMARY_TITLE_1 = 'Conversational AI for the health';
export const LANDING_PAGE_PRIMARY_TITLE_2 = 'and nutrition industries';
export const LANDING_PAGE_QUANTIZER_BODY = 'The Quantizer is a quantity and unit extractor/classifier that uses a transformer network to return scalar magnitudes and unit classes for amounts found in food descriptions. The Quantizer can idenitify non-standard, non-numeric amounts common to the food domain.';
export const LANDING_PAGE_QUANTIZER_TITLE = 'Quantizer';
export const LANDING_PAGE_RECOGNIZER_BODY = 'The Recognizer orchestrates the execution of Splitter, Tagger, Quantizer and Matcher calls when composing responses for general-purpose food entity resolution tasks.';
export const LANDING_PAGE_RECOGNIZER_TITLE = 'Recognizer';
export const LANDING_PAGE_SECONDARY_BODY_1 = 'Our platform enables developers to identify foods, display nutritional content and retrieve UPC identifiers from customers’ conversational descriptions of what they eat, buy from grocery stores or order from restaurants. We also provide tools for logging and tracking individual customers’ diets.';
export const LANDING_PAGE_SECONDARY_BODY_2 = '{cta}. Just type or say a typical meal below:';
export const LANDING_PAGE_SECONDARY_BODY_2_CTA = 'Give our system a try';
export const LANDING_PAGE_SECONDARY_INSTRUCTIONS = '';
export const LANDING_PAGE_SECONDARY_TITLE = 'We’ve solved the hard problem of identifying foods';
export const LANDING_PAGE_SERVICES_DEMO_TITLE = 'Take a peek under the hood';
export const LANDING_PAGE_SHOPPING_CART_DEMO_TITLE = 'Etiam tempor cursus justo eu tincidunt';
export const LANDING_PAGE_SPLITTER_BODY = 'The Splitter is a semantic parser that uses a temporal convolutional network (TCN) and a BERT-based transformer network to find boundaries in lists that contain more than one food.';
export const LANDING_PAGE_SPLITTER_TITLE = 'Splitter';
export const LANDING_PAGE_TAGGER_BODY = 'The Tagger is a semantic role annotator that uses a BERT-based transformer network to label individual words within food descriptions. The Tagger’s tag classes are unique to the food domain.';
export const LANDING_PAGE_TAGGER_TITLE = 'Tagger';
export const LANDING_PAGE_USE_CASES_BODY = 'Our platform makes {useCases} quicker and easier than ever before by evaluating spoken, conversational representations of food to retrieve relevant structured data.';
export const LANDING_PAGE_USE_CASES_BODY_USE_CASES = 'tracking a diet, ordering a meal, shopping for groceries and searching for nutritional information';
export const LANDING_PAGE_USE_CASES_TITLE = 'Your customers are talking... we help with the listening part';
export const LANGUAGE_NAME_DE = 'German';
export const LANGUAGE_NAME_EN = 'English';
export const LANGUAGE_NAME_ES = 'Spanish';
export const LANGUAGE_NAME_FR = 'French';
export const LANGUAGE_NAME_IT = 'Italian';
export const LOCALE_CODE = 'Locale Code';
export const LONG_MISSING_PAGE_TITLE = 'The page that you’re looking{br}for can’t be found';
export const LONG_NUMBER_CALORIES = '{num} {num, plural, one {calorie} other {calories}}';
export const LONG_NUMBER_CARBOHYDRATES = '{num} {num, plural, one {carbohydrate} other {carbohydrates}}';
export const LONG_NUMBER_FAT = '{num} {num, plural, one {calorie} other {calories}}';
export const LONG_NUMBER_PROTEIN = '{num} protein';
export const MANUFACTURER = 'MANUFACTURER';
export const MORE_QUESTIONS_QUESTION = 'More questions? {link}.';
export const MORE_QUESTIONS_QUESTION_LINK = 'Talk to us';
export const MULTIPLIER = 'Multiplier';
export const MUTATIONS = 'Mutations';
export const NAME = 'Name';
export const NUMBER_CREDITS = '{num, plural, one {# credit} other {# credits}}';
export const NUMBER_CREDITS_PER_MONTH = '{num, number} {num, plural, one {credit} other {credits}} per month';
export const NUMBER_REQUESTS_PER_MONTH = '{num, number} {num, plural, one {request} other {requests}} per month';
export const NUMBER_REQUESTS_PER_SECOND = '{num, number} {num, plural, one {request} other {requests}} per second';
export const OK = 'OK';
export const ON_THIS_PAGE = 'On this page:';
export const OOPS_SOMETHING_WENT_WRONG = 'Oops, something went wrong';
export const OUR_STORY = 'Our story';
export const OUR_TEAM = 'Our team';
export const PAGE_DESCRIPTION = 'Human | machine interactions simplified through AI';
export const PAGE_NOT_FOUND = 'Page not found';
export const PASSWORD = 'Password';
export const PER_CREDIT = 'per credit';
export const PRICING = 'Pricing';
export const PRICING_PAGE_CUSTOM_PLAN_SUBTITLE = 'Need something more?';
export const PRICING_PAGE_CUSTOM_PLAN_TITLE = 'Enterprise';
export const PRICING_PAGE_FREE_PLAN_SUBTITLE = 'Start here and grow over time';
export const PRICING_PAGE_FREE_PLAN_TITLE = 'Developer';
export const PRICING_PAGE_HERO_SUBTITLE = 'Plans that scale to fit your needs';
export const PRICING_PAGE_HERO_TITLE = 'Start building for free';
export const PRICING_PAGE_PAGE_TITLE = 'Pricing Plans';
export const PRICING_PAGE_SECONDARY_SUBTITLE = 'Once you’re ready to launch your project, provisioning for production is easy and predictable. Each GraphQL field and each REST method has its own simple pricing. Everything from a high-level Recognizer query to a low-level Tagger query is available to you as needed.';
export const PRICING_PAGE_SECONDARY_TITLE = 'Pay only for the services you use';
export const PRICING_PAGE_STANDARD_PLAN_SUBTITLE = 'For when you need performance';
export const PRICING_PAGE_STANDARD_PLAN_TITLE = 'Team';
export const PRIVACY_POLICY = 'Privacy policy';
export const PRODUCT = 'Product';
export const PROFESSIONAL_ACCESS = 'Pro Access';
export const PROTEIN = '{display, select, short {prot} other {protein}}';
export const QUERIES = 'Queries';
export const QUESTIONS_QUESTION = 'Questions?';
export const RECOGNIZING_TEXT = 'Recognizing text…';
export const REQUEST = 'Request';
export const REQUEST_ACCESS = 'Request Access';
export const REQUEST_ACCESS_SUBTITLE = 'Enter your email below to request access to our AI toolkit.';
export const REQUEST_ACCESS_TITLE = 'Request Access';
export const REST_API_AUTHENTICATION = 'Authentication';
export const REST_API_BASE_URL = 'Base URL';
export const REST_API_DOCUMENTATION_PAGE_PAGE_TITLE = 'REST Documentation';
export const REST_API_GETTING_STARTED = 'Getting started';
export const REST_API_GETTING_STARTED_PAGE_PAGE_DESCRIPTION = 'Getting started | REST Documentation';
export const REST_API_GETTING_STARTED_PAGE_PAGE_TITLE = 'Getting started | REST Documentation';
export const REST_API_GETTING_STARTED_PAGE_TITLE = 'Getting started';
export const REST_API_LOCALIZATION = 'Localization';
export const REST_API_RESPONSE_FORMAT = 'Response format';
export const REST_API_RESPONSE_FORMAT_PAGE_PAGE_TITLE = 'Response format | REST Documentation';
export const REST_API_RESPONSE_FORMAT_PAGE_TITLE = 'API response format and errors';
export const REST_KEYWORD = 'REST';
export const REST_KEYWORD_BODY_PARAMETER = 'Body parameter';
export const REST_KEYWORD_BODY_PARAMETERS = 'Body parameters';
export const REST_KEYWORD_ENDPOINT = 'Endpoint';
export const REST_KEYWORD_FILTER_PROPERTIES = 'Filter properties';
export const REST_KEYWORD_FILTER_PROPERTY = 'Filter property';
export const REST_KEYWORD_PARAMETER_TITLE = '{name}{isRequired, select, true { (required)} other {}}';
export const REST_KEYWORD_PATH_PARAMETER = 'Path parameter';
export const REST_KEYWORD_PATH_PARAMETERS = 'Path parameters';
export const REST_KEYWORD_QUERY = 'Query';
export const REST_KEYWORD_QUERY_PARAMETER = 'Query parameter';
export const REST_KEYWORD_QUERY_PARAMETERS = 'Query parameters';
export const REST_KEYWORD_REQUEST = 'Request';
export const REST_KEYWORD_REQUEST_HEADER = 'Request header';
export const REST_KEYWORD_REQUEST_HEADERS = 'Request headers';
export const REST_KEYWORD_REQUEST_URL = 'Request URL';
export const REST_KEYWORD_RESOURCES = 'Resources';
export const REST_KEYWORD_RESOURCE_METHOD = 'Resource method';
export const REST_KEYWORD_RESPONSE = 'Response';
export const REST_KEYWORD_RESPONSE_DESCRIPTION = 'Response description';
export const REST_KEYWORD_REST_METHOD = 'REST Method';
export const REST_KEYWORD_SORT_PROPERTIES = 'Sort properties';
export const REST_KEYWORD_SORT_PROPERTY = 'Sort property';
export const REST_KEYWORD_SPECIFICATION = 'Specification';
export const SAMPLE = 'Sample';
export const SEARCHING = 'Searching';
export const SHOPPING = 'Shopping';
export const SHOPPING_CART = 'Shopping cart';
export const SHORT_MISSING_PAGE_TITLE = 'The page that you’re{br}looking for can’t{br}be found';
export const SHORT_NUMBER_CARBOHYDRATES = '{num} {num, plural, one {carb} other {carbs}}';
export const SIGN_IN = 'Sign In';
export const SIGN_IN_SUBTITLE = 'Enter your email address and password below to sign in.';
export const SIGN_IN_TITLE = 'Sign In';
export const SORRY_WE_DID_NOT_FIND_ANY_RESULTS = 'Sorry, we didn’t find any results';
export const SUPPORT = 'Support';
export const SVP_TECHNOLOGY = 'SVP Technology';
export const TALK_TO_US = 'Talk to us';
export const TERMS_OF_SERVICE = 'Terms of service';
export const THE_TEAM = 'The team';
export const TRACKING = 'Tracking';
export const TRY_IT_OUT = 'Try it out';
export const UNLIMITED_CREDITS_PER_MONTH = 'Unlimited credits per month';
export const UNLIMITED_REQUESTS_PER_MONTH = 'Unlimited requests per month';
export const UNLIMITED_REQUESTS_PER_SECOND = 'Unlimited requests per second';
export const UNLIMITED_MEMBERS = 'Unlimited team members';
export const USE_CASES = 'Use cases';
export const VALUE = 'Value';
export const VP_TECHNOLOGY = 'VP Technology';
