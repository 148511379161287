// @flow strict

import styled, { css, keyframes } from 'styled-components';
import { acceleratedTimingFunction, deceleratedTimingFunction } from '../Animation/variables';
import type { StyledMassiveHeroBackgroundProps } from './MassiveHeroBackgroundProps';

import {
  imageMinimumWidth,
  imageWidthRatio,
  massiveHeroEnterAnimationDuration,
  massiveHeroExitAnimationDuration,
  massiveHeroHeightRatio,
  massiveHeroMaximumHeight,
  massiveHeroMinimumHeight,
  relHeight,
  relWidth,
} from './variables';

const animateEnterName = keyframes`
  from {
    transform: translateY(-36px);
  }

  to {
    transform: translateY(0px);
  }
`;

const animateExitName = keyframes`
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-36px);
  }
`;

const getAnimation = (props: StyledMassiveHeroBackgroundProps) => {
  const { $transitionState } = props;

  switch ($transitionState) {
    case 'entering': {
      return css`
        animation-duration: ${massiveHeroEnterAnimationDuration}ms;
        animation-name: ${animateEnterName};
        animation-timing-function: ${deceleratedTimingFunction};
      `;
    }
    case 'exiting': {
      return css`
        animation-duration: ${massiveHeroExitAnimationDuration}ms;
        animation-name: ${animateExitName};
        animation-timing-function: ${acceleratedTimingFunction};
      `;
    }
    default:
      return undefined;
  }
};

export const RawStyledMassiveHeroBackground = styled.div`
  ${getAnimation}
  position: absolute;
  height: 100%;
  left: -1000px;
  top: 0;
  width: ${imageWidthRatio * massiveHeroMinimumHeight}px;

  @media (min-height: ${massiveHeroMinimumHeight / relHeight}px) {
    width: ${100 * relWidth}vh;
  }

  @media (min-height: ${massiveHeroMaximumHeight / relHeight}px) {
    width: ${imageWidthRatio * massiveHeroMaximumHeight}px;
  }

  @media (min-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${(100 * massiveHeroHeightRatio * imageWidthRatio) / 2}vh + 50vw);
  }

  @media (max-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${imageMinimumWidth / 2}px + 50vw);
  }

  @media (min-width: 600px) and (min-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${(100 * massiveHeroHeightRatio * imageWidthRatio) / 2}vh + 32vw);
  }

  @media (min-width: 600px) and (max-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${imageMinimumWidth / 2}px + 32vw);
  }

  @media (min-width: 840px) and (min-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${(100 * massiveHeroHeightRatio * imageWidthRatio) / 2}vh + 25.667vw);
  }

  @media (min-width: 840px) and (max-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${imageMinimumWidth / 2}px + 25.667vw);
  }

  @media (min-width: 1280px) and (min-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${(100 * massiveHeroHeightRatio * imageWidthRatio) / 2}vh + 17.5vw);
  }

  @media (min-width: 1280px) and (max-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${imageMinimumWidth / 2}px + 17.5vw);
  }

  @media (min-width: 1304px) and (min-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${(100 * massiveHeroHeightRatio * imageWidthRatio) / 2}vh + 17.333%);
  }

  @media (min-width: 1304px) and (max-height: ${(massiveHeroMinimumHeight / massiveHeroHeightRatio)}px) {
    left: calc(-${imageMinimumWidth / 2}px + 17.333%);
  }
`;

export const StyledMassiveHeroBackground = RawStyledMassiveHeroBackground;
