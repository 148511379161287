// @flow strict

import React, { forwardRef } from 'react';
import { StyledMassiveHeroContentBody } from '../../styled-components/MassiveHero/MassiveHeroContentBody';
import type { MassiveHeroContentBodyProps } from './MassiveHeroContentBodyProps';

const MassiveHeroContentBody = (props: MassiveHeroContentBodyProps, ref: mixed) => (
  <StyledMassiveHeroContentBody {...props} ref={ref} />
);

export default forwardRef(MassiveHeroContentBody);
