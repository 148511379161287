// @flow strict-local

import React, { forwardRef } from 'react';
import { HashLink } from 'react-router-hash-link';

const RouterLink = (props: mixed, ref: mixed) => (
  <HashLink innerRef={ref} {...props} />
);

export default forwardRef(RouterLink);
