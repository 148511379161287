// @flow strict-local

import { ApolloProvider } from '@apollo/client';
import React, { Fragment, memo, StrictMode, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import {
  Canvas,
  CanvasModal,
  CanvasPage,
  GlobalDialogProvider,
  GraphiQLEditorReset,
  Reset,
  SpeechRecognitionProvider,
} from '../../../../..';

import AsyncRootDrawer from './AsyncRootDrawer';
import RootDrawerIsOpenContext from './RootDrawerIsOpenContext';
import RootDrawerSetIsOpenContext from './RootDrawerSetIsOpenContext';
import RootPage from './RootPage';
import translations from '../../translations';
import type { RootProps } from './RootProps';

const Root = (props: RootProps) => {
  const { apolloClient } = props;
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  return (
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <HelmetProvider>
          <IntlProvider locale={navigator.language} messages={translations.en} textComponent={Fragment}>
            <SpeechRecognitionProvider>
              <Reset />
              <GraphiQLEditorReset />
              <BrowserRouter>
                <GlobalDialogProvider>
                  <RootDrawerIsOpenContext.Provider value={drawerIsOpen}>
                    <RootDrawerSetIsOpenContext.Provider value={setDrawerIsOpen}>
                      <Canvas>
                        <CanvasPage>
                          <RootPage />
                          <AsyncRootDrawer />
                        </CanvasPage>
                        <CanvasModal />
                      </Canvas>
                    </RootDrawerSetIsOpenContext.Provider>
                  </RootDrawerIsOpenContext.Provider>
                </GlobalDialogProvider>
              </BrowserRouter>
            </SpeechRecognitionProvider>
          </IntlProvider>
        </HelmetProvider>
      </ApolloProvider>
    </StrictMode>
  );
};

export default memo(Root);
