// @flow strict

import { useCallback, useContext } from 'react';
import GlobalDialogOpenContext from '../components/Dialog/GlobalDialogOpenContext';
import GlobalDialogSetOpenContext from '../components/Dialog/GlobalDialogSetOpenContext';

const useGlobalDialogById = (id: string) => {
  const open = useContext(GlobalDialogOpenContext);
  const setOpen = useContext(GlobalDialogSetOpenContext);
  const isOpen = open === id;
  const setIsOpen = useCallback(value => setOpen(value ? id : ''), [setOpen, id]);
  const hide = useCallback(() => setIsOpen(false), [setIsOpen]);
  const show = useCallback(() => setIsOpen(true), [setIsOpen]);

  return {
    hide,
    isOpen,
    setIsOpen,
    show,
  };
};

export default useGlobalDialogById;
