// @flow strict

import styled from 'styled-components';
import { StyledTypography } from '../Typography/Typography';

export const RawStyledHeadline4 = styled(StyledTypography)`
  margin-bottom: ${props => (props.$hasSpaceBottom ? '36px' : undefined)};
  margin-top: ${props => (props.$hasSpaceTop ? '36px' : undefined)};
`;

export const StyledHeadline4 = RawStyledHeadline4;
