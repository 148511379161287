// @flow strict

import { rgb } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledTabBodyContentText = styled.span`
  color: ${props => rgb(getColorRgb(props.$color))};
  display: inline;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2.4px;
  line-height: 36px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const StyledTabBodyContentText = RawStyledTabBodyContentText;
