// @flow strict-local

import React, { useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import ForegroundColorContext from '../Foreground/ForegroundColorContext';
import ForegroundEmphasisContext from '../Foreground/ForegroundEmphasisContext';
import IconColorContext from './IconColorContext';
import IconEmphasisContext from './IconEmphasisContext';
import IconIsDenseContext from './IconIsDenseContext';
import IconIsDisabledContext from './IconIsDisabledContext';
import getContrastColor from '../Background/getContrastColor';
import { StyledIcon } from '../../styled-components/Icon/Icon';
import type { IconProps } from './IconProps';

const Icon = (props: IconProps) => {
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const parentForegroundColor = useContext(ForegroundColorContext);
  const parentForegroundEmphasis = useContext(ForegroundEmphasisContext);
  const parentIsDense = useContext(IconIsDenseContext);
  const parentIsDisabled = useContext(IconIsDisabledContext);
  const contrastColor = getContrastColor(parentBackgroundColor);
  const defaultIsDense = parentIsDense ?? false;
  const defaultIsDisabled = parentIsDisabled ?? false;
  const defaultColor = parentForegroundColor ?? contrastColor;
  const defaultEmphasis = parentForegroundEmphasis ?? 'medium';

  const {
    color = defaultColor,
    emphasis = defaultEmphasis,
    isDense = defaultIsDense,
    isDisabled = defaultIsDisabled,
    ...rest
  } = props;

  const actualColor = isDisabled ? contrastColor : color;
  const actualEmphasis = isDisabled ? 'low' : emphasis;

  return (
    <IconColorContext.Provider value={actualColor}>
      <IconEmphasisContext.Provider value={actualEmphasis}>
        <IconIsDenseContext.Provider value={isDense}>
          <StyledIcon {...rest} $isDense={isDense} />
        </IconIsDenseContext.Provider>
      </IconEmphasisContext.Provider>
    </IconColorContext.Provider>
  );
};

export default Icon;
