// @flow strict

import React, { memo } from 'react';
import StyledReset from '../../styled-components/Global/Reset';
import type { ResetProps } from './ResetProps';

const Reset = (props: ResetProps) => (
  <StyledReset {...props} />
);

export default memo(Reset);
