// @flow strict

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { StyledTypography } from '../Typography/Typography';
import type { StyledTypographyProps } from '../Typography/TypographyProps';

const getMaxWidth = (props: StyledTypographyProps) => {
  const { $lineLength } = props;
  if ($lineLength === 'short') return '720px';
  if ($lineLength === 'medium') return '960px';
  return undefined;
};

export const RawStyledBody1 = styled(StyledTypography)`
  max-width: ${getMaxWidth};
`;

export const StyledBody1 = forwardRef(
  (props: StyledBody1Props, ref: mixed) => {
    const { as = 'p', ...rest } = props;

    return (
      <RawStyledBody1 ref={ref} as={as} {...rest} />
    );
  },
);
