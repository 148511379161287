// @flow strict

import { rgb } from 'polished';
import styled from 'styled-components';
import { StyledButtonBodyText } from '../Button/ButtonBodyText';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledTextButtonBodyText = styled(StyledButtonBodyText)`
  color: ${props => rgb(getColorRgb(props.$color))};
  line-height: ${props => 36 - (2 * props.$density)}px;
`;

export const StyledTextButtonBodyText = RawStyledTextButtonBodyText;
