// @flow strict

import React, { forwardRef, useContext } from 'react';
import ToolbarDensityContext from './ToolbarDensityContext';
import { StyledToolbarBodyPrimary } from '../../styled-components/Toolbar/ToolbarBodyPrimary';
import type { ToolbarBodyPrimaryProps } from './ToolbarBodyPrimaryProps';

const ToolbarBodyPrimary = (props: ToolbarBodyPrimaryProps, ref: mixed) => {
  const density = useContext(ToolbarDensityContext);

  return (
    <StyledToolbarBodyPrimary {...props} ref={ref} $density={density} />
  );
};

export default forwardRef(ToolbarBodyPrimary);
