// @flow strict

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledHeadline5Text = styled(StyledTypographyText)`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin: -4px 0;
`;

export const StyledHeadline5Text = RawStyledHeadline5Text;
