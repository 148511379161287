// @flow strict

import { useState } from 'react';

let idCount = 0;

export default (defaultDuration: number) => {
  const [inkPresses, setInkPresses] = useState([]);

  return [inkPresses, (e: HTMLClickEvent, el: HTMLElement, duration = defaultDuration) => {
    idCount += 1;
    const { left, top } = el.getClientRects()[0];
    const id = idCount;
    const x = e.clientX - left;
    const y = e.clientY - top;
    const press = { duration, id, x, y };
    setInkPresses(presses => [...presses, press]);
    setTimeout(() => setInkPresses(([, ...presses]) => presses), duration + 40);
  }];
};
