// @flow strict-local

import React, { memo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import {
  useFormatMessage,
  useRestoreScroll,
  Page,
  PageContent,
} from '../../../../..';

import RootPageBody from './RootPageBody';
import RootPageFooter from './RootPageFooter';
import RootPageHeader from './RootPageHeader';
import AsyncRequestAccessDialog from '../RequestAccessDialog/AsyncRequestAccessDialog';
import AsyncSignInDialog from '../SignInDialog/AsyncSignInDialog';

const RootPage = () => {
  const intl = useIntl();
  const ref = useRef();

  useRestoreScroll(ref);

  return (
    <>
      <Helmet>
        <html lang={intl.locale} />
        <meta content={useFormatMessage('PAGE_DESCRIPTION')} name="description" />
        <title>Genesant AI</title>
      </Helmet>
      <Page ref={ref}>
        <PageContent>
          <RootPageHeader />
          <RootPageBody />
          <RootPageFooter />
        </PageContent>
      </Page>
      <AsyncRequestAccessDialog />
      <AsyncSignInDialog />
    </>
  );
};

export default memo(RootPage);
