// @flow strict

import React, { useContext } from 'react';
import TextButtonColorContext from './TextButtonColorContext';
import TextButtonDensityContext from './TextButtonDensityContext';
import { StyledTextButtonBody } from '../../styled-components/TextButton/TextButtonBody';
import type { TextButtonBodyProps } from './TextButtonProps';

const TextButtonBody = (props: TextButtonBodyProps) => (
  <StyledTextButtonBody
    $color={useContext(TextButtonColorContext)}
    $density={useContext(TextButtonDensityContext)}
    {...props}
  />
);

export default TextButtonBody;
