// @flow strict

import React, { useContext } from 'react';
import ForegroundColorContext from './OutlinedButtonForegroundColorContext';
import { StyledOutlinedButtonBodyText } from '../../styled-components/OutlinedButton/OutlinedButtonBodyText';
import type { OutlinedButtonBodyTextProps } from './OutlinedButtonProps';

const OutlinedButtonBodyText = (props: OutlinedButtonBodyTextProps) => (
  <StyledOutlinedButtonBodyText
    $foregroundColor={useContext(ForegroundColorContext)}
    {...props}
  />
);

export default OutlinedButtonBodyText;
