// @flow strict

import React, { Fragment, lazy, Suspense } from 'react';

const DeveloperPage = lazy(() => import('./DeveloperPage'));

const DeferredDeveloperPage = () => (
  <Suspense fallback={Fragment}>
    <DeveloperPage />
  </Suspense>
);

export default DeferredDeveloperPage;
