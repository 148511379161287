// @flow strict

import styled from 'styled-components';
import { StyledButton } from '../Button/Button';
import { largeGutterSize, smallGutterSize } from '../Grid/variables';

export const RawStyledContainedButton = styled(StyledButton)`
  min-width: 64px;
  padding: 6px ${smallGutterSize}px;
  position: relative;

  @media (min-width: 720px) {
    padding-left: ${largeGutterSize}px;
    padding-right: ${largeGutterSize}px;
  }
`;

export const StyledContainedButton = RawStyledContainedButton;
