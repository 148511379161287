// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import TransitionStateContext from '../Transition/TransitionStateContext';
import { StyledHeroBackground } from '../../styled-components/Hero/HeroBackground';
import type { HeroBackgroundProps } from './HeroBackgroundProps';

const HeroBackground = (props: HeroBackgroundProps, ref: mixed) => {
  const transitionState = useContext(TransitionStateContext);

  return (
    <StyledHeroBackground
      {...props}
      ref={ref}
      $color={useContext(BackgroundColorContext)}
      $transitionState={transitionState}
    />
  );
};

export default forwardRef(HeroBackground);
