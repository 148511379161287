// @flow strict

import styled from 'styled-components';

export const RawStyledTab = styled.button`
  cursor: pointer;
  display: inline-block;
  height: 48px;
  padding-bottom: 6px;
  padding-top: 6px;
  pointer-events: auto;
  width: 100%;
`;

export const StyledTab = RawStyledTab;
