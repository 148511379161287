// @flow strict

import React, { forwardRef } from 'react';
import PropagatedTransition from '../Transition/PropagatedTransition';
import type { MassiveHeroTransitionProps } from './MassiveHeroTransitionProps';
import { massiveHeroEnterAnimationDuration, massiveHeroExitAnimationDuration } from '../../styled-components/MassiveHero/variables';

const timeout = {
  enter: massiveHeroEnterAnimationDuration,
  exit: massiveHeroExitAnimationDuration,
};

const MassiveHeroTransition = forwardRef(
  (props: MassiveHeroTransitionProps, ref) => (
    <PropagatedTransition {...props} ref={ref} timeout={timeout} />
  ),
);

export default MassiveHeroTransition;
