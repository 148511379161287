// @flow strict

import React, { forwardRef } from 'react';
import { StyledGridCell } from '../../styled-components/Grid/GridCell';
import type { GridCellProps } from './GridCellProps';

const GridCell = (props: GridCellProps, ref: mixed) => {
  const {
    alignment,
    columnOffset,
    columnSpan,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  return (
    <StyledGridCell
      ref={ref}
      $alignment={alignment}
      $columnOffset={columnOffset}
      $columnSpan={columnSpan}
      $spaceBottom={spaceBottom}
      $spaceLeft={spaceLeft}
      $spaceRight={spaceRight}
      $spaceTop={spaceTop}
      {...rest}
    />
  );
};

export default forwardRef(GridCell);
