// @flow strict

import styled from 'styled-components';

export const RawStyledCanvas = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const StyledCanvas = RawStyledCanvas;
