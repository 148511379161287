// @flow strict

import useOnlineStatus from '@rehooks/online-status';
import React, { useCallback, useEffect, useState } from 'react';
import SpeechRecognitionAbortContext from './SpeechRecognitionAbortContext';
import SpeechRecognitionIsDisconnectedContext from './SpeechRecognitionIsDisconnectedContext';
import SpeechRecognitionIsSupportedContext from './SpeechRecognitionIsSupportedContext';
import SpeechRecognitionPermissionContext from './SpeechRecognitionPermissionContext';
import SpeechRecognitionResultContext from './SpeechRecognitionResultContext';
import SpeechRecognitionStartContext from './SpeechRecognitionStartContext';
import SpeechRecognitionStateContext from './SpeechRecognitionStateContext';
import SpeechRecognitionStopContext from './SpeechRecognitionStopContext';
import type { SpeechRecognitionProviderProps } from './SpeechRecognitionProviderProps';
import createSpeechRecognizer from './createSpeechRecognizer';

const SpeechRecognitionIsSupportedProvider = (props: SpeechRecognitionProviderProps) => {
  const { children } = props;
  const [permission, setPermission] = useState(null);
  const [result, setResult] = useState();
  const [state, setState] = useState('isNotRunning');
  const isDisconnected = !useOnlineStatus();

  const handleSpeechRecognizerResult = useCallback(
    (e) => setResult(e),
    [setResult],
  );

  const handleSpeechRecognizerStart = useCallback(
    () => setState('isRunning'),
    [setState],
  );

  const handleSpeechRecognizerStop = useCallback(
    () => setState('isNotRunning'),
    [setState],
  );

  const [speechRecognizer] = useState(createSpeechRecognizer({
    continuous: true,
    interimResults: true,
    onEnd: handleSpeechRecognizerStop,
    onResult: handleSpeechRecognizerResult,
    onStart: handleSpeechRecognizerStart,
  }));

  const handleChangeMicrophonePermission = useCallback(
    (nextState: string) => {
      switch (nextState) {
        case 'denied':
          return setPermission('isDenied');
        case 'granted':
          return setPermission('isGranted');
        case 'prompt':
          return setPermission('isUnrequested');
        default:
          return console.error(`Unrecognized permission state “${nextState}”`);
      }
    },
    [setPermission],
  );

  useEffect(
    () => {
      (async () => {
        const status = await navigator.permissions.query({ name: 'microphone' });
        handleChangeMicrophonePermission(status.state);

        status.addEventListener('change', (e) => {
          const { target } = e;
          handleChangeMicrophonePermission(target.state);
        });
      })();
    },
    [handleChangeMicrophonePermission],
  );

  const abort = useCallback(
    () => speechRecognizer.abort(),
    [speechRecognizer],
  );

  const start = useCallback(
    () => {
      if (permission === 'isUnrequested') {
        setPermission('isRequesting');
        setState('isWaitingToRun');
      }

      speechRecognizer.start();
    },
    [setPermission, setState, permission, speechRecognizer],
  );

  const stop = useCallback(
    () => speechRecognizer.stop(),
    [speechRecognizer],
  );

  return (
    <SpeechRecognitionAbortContext.Provider value={abort}>
      <SpeechRecognitionIsDisconnectedContext.Provider value={isDisconnected}>
        <SpeechRecognitionIsSupportedContext.Provider value>
          <SpeechRecognitionPermissionContext.Provider value={permission}>
            <SpeechRecognitionResultContext.Provider value={result}>
              <SpeechRecognitionStartContext.Provider value={start}>
                <SpeechRecognitionStateContext.Provider value={state}>
                  <SpeechRecognitionStopContext.Provider value={stop}>
                    {children}
                  </SpeechRecognitionStopContext.Provider>
                </SpeechRecognitionStateContext.Provider>
              </SpeechRecognitionStartContext.Provider>
            </SpeechRecognitionResultContext.Provider>
          </SpeechRecognitionPermissionContext.Provider>
        </SpeechRecognitionIsSupportedContext.Provider>
      </SpeechRecognitionIsDisconnectedContext.Provider>
    </SpeechRecognitionAbortContext.Provider>
  );
};

export default SpeechRecognitionIsSupportedProvider;
