// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import HeroIsInAGroupContext from './HeroIsInAGroupContext';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import TransitionStateContext from '../Transition/TransitionStateContext';
import { StyledHero } from '../../styled-components/Hero/Hero';
import type { HeroProps } from './HeroProps';

const Hero = (props: HeroProps, ref: mixed) => {
  const isInAGroup = useContext(HeroIsInAGroupContext);
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const transitionState = useContext(TransitionStateContext);
  const { backgroundColor = parentBackgroundColor, offsetBy = null, ...rest } = props;

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <StyledHero
        {...rest}
        ref={ref}
        $isInAGroup={isInAGroup}
        $offsetBy={offsetBy}
        $transitionState={transitionState}
      />
    </BackgroundColorContext.Provider>
  );
};

export default forwardRef(Hero);
