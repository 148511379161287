// @flow strict

import styled from 'styled-components';
import getSpaceSize from './getSpaceSize';

export const RawStyledSpacer = styled.div`
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  margin-bottom: ${props => (props.$spaceBottom == null ? undefined : `${getSpaceSize(props.$spaceBottom)}px`)};
  margin-left: ${props => (props.$spaceLeft == null ? undefined : `${getSpaceSize(props.$spaceLeft)}px`)};
  margin-right: ${props => (props.$spaceRight == null ? undefined : `${getSpaceSize(props.$spaceRight)}px`)};
  margin-top: ${props => (props.$spaceTop == null ? undefined : `${getSpaceSize(props.$spaceTop)}px`)};
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
`;

export const StyledSpacer = RawStyledSpacer;
