// @flow strict

import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useFormatMessage = (id: string) => {
  const intl = useIntl();

  return useMemo(
    () => intl.formatMessage({ id }),
    [intl],
  );
};

export default useFormatMessage;
