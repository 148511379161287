// @flow strict

import { rgba } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';
import { RawStyledTab } from './Tab';
import { standardTimingFunction } from '../Animation/variables';
import { largeGutterSize, smallGutterSize } from '../Grid/variables';

export const RawStyledTabBody = styled.div`
  border-radius: 4px;
  height: 36px;
  min-width: 96px;
  overflow: hidden;
  padding-left: ${smallGutterSize}px;
  padding-right: ${smallGutterSize}px;
  position: relative;
  text-align: center;
  transition-duration: 150ms;
  transition-property: background-color;
  transition-timing-function: ${standardTimingFunction};

  @media (min-width: 720px) {
    padding-left: ${largeGutterSize}px;
    padding-right: ${largeGutterSize}px;
  }

  ${RawStyledTab}:hover & {
    background-color: ${props => rgba({ ...getColorRgb(props.$color), alpha: 0.08 })};
  }

  ${RawStyledTab}:focus & {
    background-color: ${props => rgba({ ...getColorRgb(props.$color), alpha: 0.16 })};
  }

  ${RawStyledTab}:active & {
    background-color: ${props => rgba({ ...getColorRgb(props.$color), alpha: 0.24 })};
  }
`;

export const StyledTabBody = RawStyledTabBody;
