// @flow strict

import styled from 'styled-components';
import { StyledButton } from '../Button/Button';

export const RawStyledTextButton = styled(StyledButton)`
  height: ${props => 48 - (4 * props.$density)}px;
  min-width: 48px;
  padding-bottom: ${props => 6 - props.$density}px;
  padding-top: ${props => 6 - props.$density}px;
  position: relative;
`;

export const StyledTextButton = RawStyledTextButton;
