// @flow strict

import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageBody } from '../../../../..';
import DeferredAboutPage from '../AboutPage/DeferredAboutPage';
import DeferredPricingPage from '../PricingPage/DeferredPricingPage';
import DeferredDeveloperPage from '../DeveloperRoutes/DeferredDeveloperPage';
import DeferredMissingPage from '../MissingPage/DeferredMissingPage';
import LandingPage from '../LandingPage';

const RootPageBody = () => (
  <PageBody>
    <Switch>
      <Route component={LandingPage} exact path="/" />
      <Route component={DeferredAboutPage} path="/about" />
      <Route component={DeferredDeveloperPage} path="/developer" />
      <Route component={DeferredPricingPage} path="/pricing" />
      <Route component={DeferredMissingPage} />
    </Switch>
  </PageBody>
);

export default memo(RootPageBody);
