// @flow strict

import styled from 'styled-components';

export const RawStyledLogoText = styled.i`
  align-items: center;
  display: inline-flex;
  height: 22px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const StyledLogoText = RawStyledLogoText;
