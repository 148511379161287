// @flow

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist-dev';
import { createInstance as createLocalforage, INDEXEDDB } from 'localforage';

type CreateGraphqlClientOptions = {
  genesantApiUrl: string,
};

export default async (opts: CreateGraphqlClientOptions) => {
  const { genesantApiUrl } = opts;
  const cache = new InMemoryCache();
  const storage = createLocalforage({ driver: INDEXEDDB });
  const link = createHttpLink({ uri: genesantApiUrl });
  await persistCache({ cache, storage });
  return new ApolloClient({ cache, link });
};
