// @flow strict

import React from 'react';
import { StyledBody1Text } from '../../styled-components/Body1/Body1Text';
import type { Body1TextProps } from './Body1TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Body1Text = (props: Body1TextProps) => (
  <StyledBody1Text {...props} />
);

export default withTypographyText(Body1Text);
