// @flow strict

import React, { forwardRef } from 'react';
import { StyledGrid } from '../../styled-components/Grid/Grid';
import type { GridProps } from './GridProps';

const Grid = (props: GridProps, ref: mixed) => {
  const {
    cellAlignment,
    shouldAutoFit = false,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  return (
    <StyledGrid
      {...rest}
      ref={ref}
      $cellAlignment={cellAlignment}
      $shouldAutoFit={shouldAutoFit}
      $spaceBottom={spaceBottom}
      $spaceLeft={spaceLeft}
      $spaceRight={spaceRight}
      $spaceTop={spaceTop}
    />
  );
};

export default forwardRef(Grid);
