// @flow strict

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledHeadline4Text = styled(StyledTypographyText)`
  font-size: 34px;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 40px;
  margin: -3px 0;
`;

export const StyledHeadline4Text = RawStyledHeadline4Text;
