// @flow strict

import React, { useContext } from 'react';
import ForegroundColorContext from '../Foreground/ForegroundColorContext';
import { StyledIconButtonBody } from '../../styled-components/IconButton/IconButtonBody';
import type { IconButtonBodyProps } from './IconButtonBodyProps';

const IconButtonBody = (props: IconButtonBodyProps) => (
  <StyledIconButtonBody
    {...props}
    $color={useContext(ForegroundColorContext)}
  />
);

export default IconButtonBody;
