// @flow strict-local

import React from 'react';
import { StyledHeroBackgroundImageSvg2 } from '../../styled-components/Hero/HeroBackgroundImageSvg2';

const HeroBackgroundImageSvg2 = () => (
  <StyledHeroBackgroundImageSvg2 />
);

export default HeroBackgroundImageSvg2;
