// @flow strict-local

import React, { forwardRef } from 'react';
import { StyledMassiveHeroBackgroundTopImage } from '../../styled-components/MassiveHero/MassiveHeroBackgroundTopImage';
import type { MassiveHeroBackgroundTopImageProps } from './MassiveHeroBackgroundTopImageProps';

const MassiveHeroBackgroundTopImage = (props: MassiveHeroBackgroundTopImageProps, ref: mixed) => (
  <StyledMassiveHeroBackgroundTopImage {...props} ref={ref} />
);

export default forwardRef(MassiveHeroBackgroundTopImage);
