// @flow strict

import React from 'react';
import { StyledSubtitle1Text } from '../../styled-components/Subtitle1/Subtitle1Text';
import type { Subtitle1TextProps } from './Subtitle1TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Subtitle1Text = (props: Subtitle1TextProps) => (
  <StyledSubtitle1Text {...props} />
);

export default withTypographyText(Subtitle1Text);
