// @flow strict

import React from 'react';
import { StyledHeadline5 } from '../../styled-components/Headline5/Headline5';
import type { Headline5Props } from './Headline5Props';
import withTypography from '../Typography/withTypography';

const Headline5 = (props: Headline5Props) => (
  <StyledHeadline5 {...props} />
);

export default withTypography(Headline5);
