// @flow strict

import React, { forwardRef } from 'react';
import { StyledPageFooter } from '../../styled-components/Page/PageFooter';
import type { PageFooterProps } from './PageFooterProps';

const PageFooter = (props: PageFooterProps, ref: mixed) => (
  <StyledPageFooter {...props} ref={ref} />
);

export default forwardRef(PageFooter);
