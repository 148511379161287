// @flow strict

import type { Color } from './Color';

import {
  darkColorRgb,
  darkPrimaryColorRgb,
  lightColorRgb,
  negativeColorRgb,
  positiveColorRgb,
  primaryColorRgb,
  primaryDarkColorRgb,
  quaternaryColorRgb,
  secondaryColorRgb,
  slightlyNegativeColorRgb,
  tertiaryColorRgb,
  vibrantSecondaryRgb,
  vibrantTertiaryRgb,
  xDarkColorRgb,
  xLightColorRgb,
} from './variables';

export default (color: Color) => {
  switch (color) {
    case 'dark': return darkColorRgb;
    case 'darkPrimary': return darkPrimaryColorRgb;
    case 'light': return lightColorRgb;
    case 'negative': return negativeColorRgb;
    case 'positive': return positiveColorRgb;
    case 'primary': return primaryColorRgb;
    case 'primaryDark': return primaryDarkColorRgb;
    case 'quaternary': return quaternaryColorRgb;
    case 'secondary': return secondaryColorRgb;
    case 'slightlyNegative': return slightlyNegativeColorRgb;
    case 'tertiary': return tertiaryColorRgb;
    case 'vibrantSecondary': return vibrantSecondaryRgb;
    case 'vibrantTertiary': return vibrantTertiaryRgb;
    case 'xDark': return xDarkColorRgb;
    case 'xLight': return xLightColorRgb;
    default: throw new Error(`Unknown color “${color}”`);
  }
};
