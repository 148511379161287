// @flow strict-local

import styled, { css } from 'styled-components';
import getSharedCss from './getSharedCss';
import type { HeroProps } from './HeroProps';

const getCss = (props: HeroProps) => {
  if (props.$isInAGroup) {
    return css`
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    `;
  }

  return getSharedCss(props);
};

export const RawStyledHero = styled.div`
  ${getCss}
`;

export const StyledHero = RawStyledHero;
