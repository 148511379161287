// @flow strict

import React, { forwardRef, useContext } from 'react';
import TransitionStateContext from '../Transition/TransitionStateContext';
import { StyledMassiveHeroContent } from '../../styled-components/MassiveHero/MassiveHeroContent';
import type { MassiveHeroContentProps } from './MassiveHeroContentProps';

const MassiveHeroContent = (props: MassiveHeroContentProps, ref: mixed) => (
  <StyledMassiveHeroContent
    {...props}
    ref={ref}
    $transitionState={useContext(TransitionStateContext)}
  />
);

export default forwardRef(MassiveHeroContent);
