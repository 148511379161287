// @flow strict

import React from 'react';
import { StyledHeadline3Text } from '../../styled-components/Headline3/Headline3Text';
import type { Headline3TextProps } from './Headline3TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Headline3Text = (props: Headline3TextProps) => (
  <StyledHeadline3Text {...props} />
);

export default withTypographyText(Headline3Text);
