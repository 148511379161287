// @flow strict

import React from 'react';
import { StyledPageBody } from '../../styled-components/Page/PageBody';
import type { PageBodyProps } from './PageBodyProps';

const PageBody = (props: PageBodyProps) => (
  <StyledPageBody {...props} />
);

export default PageBody;
