// @flow strict

import React, { useContext } from 'react';
import flattenChildren from 'react-flatten-children';
import TabGroupSetTabBodyContentDimsContext from './TabGroupSetTabBodyContentDimsContext';
import TabGroupTabBodyContentDimsContext from './TabGroupTabBodyContentDimsContext';
import TabGroupSelectedTabIndexContext from './TabGroupSelectedTabIndexContext';
import TabGroupTabsAreEqualWidthContext from './TabGroupTabsAreEqualWidthContext';
import TabIsSelectedContext from './TabIsSelectedContext';
import TabOnChangeBodyContentDimContext from './TabOnChangeBodyContentDimContext';
import { StyledTabGroupTabs } from '../../styled-components/Tab/TabGroupTabs';
import type { TabGroupTabsProps } from './TabGroupTabsProps';

const TabGroupTabs = (props: TabGroupTabsProps) => {
  const selectedTabIndex = useContext(TabGroupSelectedTabIndexContext);
  const setTabBodyContentDims = useContext(TabGroupSetTabBodyContentDimsContext);
  const tabBodyContentDims = useContext(TabGroupTabBodyContentDimsContext);
  const { areEqualWidth = false, children, ...rest } = props;

  const nextChildren = flattenChildren(children).map(
    (child, i) => {
      const onChangeBodyContentDim = (width: number, x: number) => {
        const prevDim = tabBodyContentDims[i];

        if (prevDim?.width !== width || prevDim?.x !== x) {
          setTabBodyContentDims({
            ...tabBodyContentDims,
            [i]: { width, x },
          });
        }
      };

      return (
        /* eslint-disable-next-line react/no-array-index-key */
        <TabIsSelectedContext.Provider key={i} value={selectedTabIndex === i}>
          <TabOnChangeBodyContentDimContext.Provider value={onChangeBodyContentDim}>
            {child}
          </TabOnChangeBodyContentDimContext.Provider>
        </TabIsSelectedContext.Provider>
      );
    },
  );

  return (
    <TabGroupTabsAreEqualWidthContext.Provider value={areEqualWidth}>
      <StyledTabGroupTabs {...rest}>
        {nextChildren}
      </StyledTabGroupTabs>
    </TabGroupTabsAreEqualWidthContext.Provider>
  );
};

export default TabGroupTabs;
