// @flow strict-local

import { rgb, rgba } from 'polished';
import { css } from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';
import type { StyledScrollBarsProps } from './ScrollBarsProps';

export default (opts: StyledScrollBarsProps) => {
  const { $backgroundColor, $contrastColor } = opts;
  const $backgroundColorRgb = getColorRgb($backgroundColor);
  const $contrastColorRgb = getColorRgb($contrastColor);

  return css`
    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    ::-webkit-scrollbar-corner {
      background-color: ${rgb($backgroundColorRgb)};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${rgba({ ...$contrastColorRgb, alpha: 0.12 })};
      border: 2px solid ${rgb($backgroundColorRgb)};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${rgb($backgroundColorRgb)};
      border: 2px solid ${rgb($backgroundColorRgb)};
      padding: 2px;
    }
  `;
};
