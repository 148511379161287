// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import getContrastColor from '../Background/getContrastColor';
import { StyledSection } from '../../styled-components/Section/Section';
import type { SectionProps } from './SectionProps';

const Section = (props: SectionProps, ref: mixed) => {
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const { innerSpaceBottom, innerSpaceTop } = props;

  const {
    backgroundColor = parentBackgroundColor,
    canScroll = false,
    desktopInnerSpaceBottom = innerSpaceBottom === undefined ? 16 : innerSpaceBottom,
    desktopInnerSpaceTop = innerSpaceTop === undefined ? 16 : innerSpaceTop,
    mobileInnerSpaceBottom = innerSpaceBottom === undefined ? 8 : innerSpaceBottom,
    mobileInnerSpaceTop = innerSpaceTop === undefined ? 8 : innerSpaceTop,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  const contrastColor = getContrastColor(backgroundColor);

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <StyledSection
        {...rest}
        ref={ref}
        $backgroundColor={backgroundColor}
        $canScroll={canScroll}
        $contrastColor={contrastColor}
        $desktopInnerSpaceBottom={desktopInnerSpaceBottom}
        $desktopInnerSpaceTop={desktopInnerSpaceTop}
        $mobileInnerSpaceBottom={mobileInnerSpaceBottom}
        $mobileInnerSpaceTop={mobileInnerSpaceTop}
        $spaceBottom={spaceBottom}
        $spaceLeft={spaceLeft}
        $spaceRight={spaceRight}
        $spaceTop={spaceTop}
      />
    </BackgroundColorContext.Provider>
  );
};

export default forwardRef(Section);
