// @flow strict

import React, { useCallback, useContext, useRef } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import getContrastColor from '../Background/getContrastColor';
import useInkPresses from '../Ink/useInkPresses';
import { StyledTab } from '../../styled-components/Tab/Tab';
import { tabBodyInkRippleDuration } from '../../styled-components/Tab/variables';
import ColorContext from './TabColorContext';
import InkPressesContext from '../Ink/InkPressesContext';
import TabGroupColorContext from './TabGroupColorContext';
import type { TabProps } from './TabProps';

const Tab = (props: TabProps) => {
  const { color: tabColor, onClick, selectedTabIndex, ...rest } = props;
  const ref = useRef();
  const [inkPresses, handleInkPress] = useInkPresses(tabBodyInkRippleDuration);
  const backgroundColor = useContext(BackgroundColorContext);
  const tabGroupColor = useContext(TabGroupColorContext);
  const color = tabColor ?? (tabGroupColor || getContrastColor(backgroundColor));

  const handleClick = useCallback((e: HTMLEvent) => {
    if (onClick) onClick(e);
    if (e.target) e.target.blur();
    handleInkPress(e, ref.current);
  }, []);

  return (
    <ColorContext.Provider value={color}>
      <InkPressesContext.Provider value={inkPresses}>
        <StyledTab {...rest} ref={ref} onClick={handleClick} />
      </InkPressesContext.Provider>
    </ColorContext.Provider>
  );
};

export default Tab;
