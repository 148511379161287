// @flow strict-local

import styled from 'styled-components';
import { RawStyledTypographyText } from '../Typography/TypographyText';

export const RawStyledSubtitle1Text = styled(RawStyledTypographyText)`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin: -2px 0;
`;

export const StyledSubtitle1Text = RawStyledSubtitle1Text;
