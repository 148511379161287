// @flow strict

import React, { useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import ColorContext from './InkColorContext';
import DurationContext from './InkRippleDurationContext';
import PressesContext from './InkPressesContext';
import { StyledInk } from '../../styled-components/Ink/Ink';
import type { InkProps } from './InkProps';
import getContrastColor from '../Background/getContrastColor';
import InkRipple from './InkRipple';

const Ink = (props: InkProps) => {
  const backgroundColor = useContext(BackgroundColorContext);
  const defaultColor = useContext(ColorContext) || getContrastColor(backgroundColor);
  const defaultDuration = useContext(DurationContext);
  const defaultPresses = useContext(PressesContext);

  const {
    color = defaultColor,
    duration = defaultDuration,
    presses = defaultPresses,
    ...rest
  } = props;

  return (
    <ColorContext.Provider value={color}>
      <DurationContext.Provider value={duration}>
        <StyledInk {...rest}>
          {presses.map(press => (
            <InkRipple
              key={press.id}
              color={press.color}
              duration={press.duration}
              x={press.x}
              y={press.y}
            />
          ))}
        </StyledInk>
      </DurationContext.Provider>
    </ColorContext.Provider>
  );
};

export default Ink;
