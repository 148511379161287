// @flow strict

import React, { useContext } from 'react';
import BackgroundColorContext from './ContainedButtonBackgroundColorContext';
import ContrastColorContext from './ContainedButtonContrastColorContext';
import { StyledContainedButtonBody } from '../../styled-components/ContainedButton/ContainedButtonBody';
import type { ContainedButtonBodyProps } from './ContainedButtonProps';

const ContainedButtonBody = (props: ContainedButtonBodyProps) => (
  <StyledContainedButtonBody
    $backgroundColor={useContext(BackgroundColorContext)}
    $contrastColor={useContext(ContrastColorContext)}
    {...props}
  />
);

export default ContainedButtonBody;
