// @flow strict

import React, { type Component, useContext } from 'react';
import AlignmentContext from './TypographyAlignmentContext';
import ForegroundColorContext from '../Foreground/ForegroundColorContext';
import ForegroundIsCodeContext from '../Foreground/ForegroundIsCodeContext';
import ForegroundEmphasisContext from '../Foreground/ForegroundEmphasisContext';
import TypographyIsItalicContext from './TypographyIsItalicContext';
import TypographyWeightContext from './TypographyWeightContext';
import type { TypographyProps } from './TypographyProps';
import type { StyledTypographyTextProps } from '../../styled-components/Typography/TypographyTextProps';

const withTypographyText = (
  (StyledTypographyText: Component<StyledTypographyTextProps>) => (
    (props: TypographyProps) => {
      const { as, ...rest } = props;

      return (
        <StyledTypographyText
          {...rest}
          $alignment={useContext(AlignmentContext)}
          $color={useContext(ForegroundColorContext)}
          $emphasis={useContext(ForegroundEmphasisContext)}
          $isCode={useContext(ForegroundIsCodeContext)}
          $isItalic={useContext(TypographyIsItalicContext)}
          $weight={useContext(TypographyWeightContext)}
          forwardedAs={as}
        />
      );
    }
  )
);

export default withTypographyText;
