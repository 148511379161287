// @flow strict-local

import { TransitionGroup } from '@material-ui/react-transition-group';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Breakpoint,
  ContainedButton,
  ContainedButtonBody,
  ContainedButtonBodyText,
  Grid,
  GridCell,
  Headline3,
  Headline3Text,
  Headline4,
  Headline4Text,
  Headline5,
  Headline5Text,
  Headline6,
  Headline6Text,
  MassiveHero,
  MassiveHeroBackground,
  MassiveHeroBackgroundBottomImage,
  MassiveHeroBackgroundBottomImageSvg,
  MassiveHeroBackgroundTopImage,
  MassiveHeroBackgroundTopImageSvg,
  MassiveHeroContent,
  MassiveHeroContentBody,
  MassiveHeroContentFooter,
  MassiveHeroTransition,
  OutlinedButton,
  OutlinedButtonBody,
  OutlinedButtonBodyText,
  Subtitle1,
  Subtitle1Text,
  useGlobalDialogById,
  type MassiveHeroProps,
} from '../../../../../..';

const heroTitleText = (
  <>
    <FormattedMessage id="LANDING_PAGE_PRIMARY_TITLE_1" />
    <br />
    <FormattedMessage id="LANDING_PAGE_PRIMARY_TITLE_2" />
  </>
);

const heroSubtitleText = (
  <FormattedMessage id="LANDING_PAGE_PRIMARY_SUBTITLE" />
);

const HeroPrimaryButton = () => {
  const { show: handleClick } = useGlobalDialogById('REQUEST_ACCESS');

  return (
    <ContainedButton backgroundColor="primary" isFullWidth onClick={handleClick}>
      <ContainedButtonBody>
        <ContainedButtonBodyText>
          <FormattedMessage id="REQUEST_ACCESS" />
        </ContainedButtonBodyText>
      </ContainedButtonBody>
    </ContainedButton>
  );
};

const HeroSecondaryButton = () => {
  const { show } = useGlobalDialogById('SIGN_IN');

  return (
    <OutlinedButton backgroundColor="primary" isFullWidth onClick={show}>
      <OutlinedButtonBody>
        <OutlinedButtonBodyText>
          <FormattedMessage id="SIGN_IN" />
        </OutlinedButtonBodyText>
      </OutlinedButtonBody>
    </OutlinedButton>
  );
};

const LandingPageMassiveHero = (props: MassiveHeroProps) => (
  <MassiveHero backgroundColor="darkPrimary" {...props}>
    <MassiveHeroBackground>
      <MassiveHeroBackgroundBottomImage>
        <MassiveHeroBackgroundBottomImageSvg />
      </MassiveHeroBackgroundBottomImage>
      <MassiveHeroBackgroundTopImage>
        <MassiveHeroBackgroundTopImageSvg />
      </MassiveHeroBackgroundTopImage>
    </MassiveHeroBackground>
    <MassiveHeroContent>
      <MassiveHeroContentBody>
        <Breakpoint end={1}>
          <Headline6 as="h1" emphasis="high" spaceTop="medium">
            <Headline6Text>
              {heroTitleText}
            </Headline6Text>
          </Headline6>
        </Breakpoint>
        <Breakpoint end={4} start={1}>
          <Headline5 as="h1" emphasis="high" spaceTop="medium">
            <Headline5Text>
              {heroTitleText}
            </Headline5Text>
          </Headline5>
        </Breakpoint>
        <Breakpoint end={5} start={4}>
          <Headline4 as="h1" emphasis="high" spaceTop="medium">
            <Headline4Text>
              {heroTitleText}
            </Headline4Text>
          </Headline4>
        </Breakpoint>
        <Breakpoint start={5}>
          <Headline3 as="h1" emphasis="high" spaceTop="medium">
            <Headline3Text>
              {heroTitleText}
            </Headline3Text>
          </Headline3>
        </Breakpoint>
        <Breakpoint end={4}>
          <Subtitle1 as="h2" spaceBottom="large" spaceTop="medium">
            <Subtitle1Text>
              {heroSubtitleText}
            </Subtitle1Text>
          </Subtitle1>
        </Breakpoint>
        <Breakpoint end={5} start={4}>
          <Headline6 as="h2" spaceBottom="xLarge" spaceTop="medium">
            <Headline6Text>
              {heroSubtitleText}
            </Headline6Text>
          </Headline6>
        </Breakpoint>
        <Breakpoint start={5}>
          <Headline5 as="h2" spaceBottom="xxxLarge" spaceTop="medium">
            <Headline5Text>
              {heroSubtitleText}
            </Headline5Text>
          </Headline5>
        </Breakpoint>
      </MassiveHeroContentBody>
      <MassiveHeroContentFooter>
        <Breakpoint end={3}>
          <HeroPrimaryButton />
          <HeroSecondaryButton />
        </Breakpoint>
        <Breakpoint end={4} start={3}>
          <Grid>
            <GridCell columnSpan={4}>
              <HeroPrimaryButton />
            </GridCell>
            <GridCell columnSpan={4}>
              <HeroSecondaryButton />
            </GridCell>
          </Grid>
        </Breakpoint>
        <Breakpoint end={6} start={4}>
          <Grid>
            <GridCell columnSpan={5}>
              <HeroPrimaryButton />
            </GridCell>
            <GridCell columnSpan={5}>
              <HeroSecondaryButton />
            </GridCell>
          </Grid>
        </Breakpoint>
        <Breakpoint end={9} start={6}>
          <Grid>
            <GridCell columnSpan={6}>
              <HeroPrimaryButton />
            </GridCell>
            <GridCell columnSpan={6}>
              <HeroSecondaryButton />
            </GridCell>
          </Grid>
        </Breakpoint>
        <Breakpoint start={9}>
          <Grid>
            <GridCell columnSpan={4}>
              <HeroPrimaryButton />
            </GridCell>
            <GridCell columnSpan={4}>
              <HeroSecondaryButton />
            </GridCell>
          </Grid>
        </Breakpoint>
      </MassiveHeroContentFooter>
    </MassiveHeroContent>
  </MassiveHero>
);

const ResponsiveLandingPageMassiveHero = () => (
  <>
    <Breakpoint end={7}>
      <TransitionGroup appear component={null}>
        <MassiveHeroTransition>
          <LandingPageMassiveHero offsetBy="toolbarExtended" />
        </MassiveHeroTransition>
      </TransitionGroup>
    </Breakpoint>
    <Breakpoint start={7}>
      <TransitionGroup appear component={null}>
        <MassiveHeroTransition>
          <LandingPageMassiveHero offsetBy="toolbar" />
        </MassiveHeroTransition>
      </TransitionGroup>
    </Breakpoint>
  </>
);

export default memo(ResponsiveLandingPageMassiveHero);
