// @flow strict

import React from 'react';
import { StyledLogoButton } from '../../styled-components/LogoButton/LogoButton';
import type { LogoButtonProps } from './LogoButtonProps';

const LogoButton = (props: LogoButtonProps) => (
  <StyledLogoButton {...props} />
);

export default LogoButton;
