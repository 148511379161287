// @flow strict

import React, { Fragment, lazy, Suspense } from 'react';

const AboutPage = lazy(() => import('./AboutPage'));

const DeferredAboutPage = () => (
  <Suspense fallback={Fragment}>
    <AboutPage />
  </Suspense>
);

export default DeferredAboutPage;
