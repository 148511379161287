// @flow strict

import type { SpeechRecognitionProviderProps } from './SpeechRecognitionProviderProps';

const SpeechRecognitionIsUnsupportedProvider = (props: SpeechRecognitionProviderProps) => {
  const { children } = props;
  return children;
};

export default SpeechRecognitionIsUnsupportedProvider;
