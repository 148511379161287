// @flow strict

import React, { useContext } from 'react';
import BackgroundColorContext from './OutlinedButtonBackgroundColorContext';
import { StyledOutlinedButtonBody } from '../../styled-components/OutlinedButton/OutlinedButtonBody';
import type { OutlinedButtonBodyProps } from './OutlinedButtonProps';

const OutlinedButtonBody = (props: OutlinedButtonBodyProps) => (
  <StyledOutlinedButtonBody
    $backgroundColor={useContext(BackgroundColorContext)}
    {...props}
  />
);

export default OutlinedButtonBody;
