// @flow strict

import React from 'react';
import type { LayoutItemProps } from './LayoutItemProps';
import { StyledLayoutItem } from '../../styled-components/Layout/LayoutItem';

const LayoutItem = (props: LayoutItemProps) => {
  const {
    alignment,
    grow,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  return (
    <StyledLayoutItem
      {...rest}
      $alignment={alignment}
      $flexGrow={typeof grow === 'boolean' ? '1' : grow}
      $spaceBottom={spaceBottom}
      $spaceLeft={spaceLeft}
      $spaceRight={spaceRight}
      $spaceTop={spaceTop}
    />
  );
};

export default LayoutItem;
