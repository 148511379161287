// @flow strict-local

import { rgba } from 'polished';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { largeGutterSize, smallGutterSize } from '../Grid/variables';
import getColorRgb from '../Palette/getColorRgb';
import { RawStyledSpacer } from '../Spacer/Spacer';
import type { StyledDividerProps } from './DividerProps';

export const RawStyledDivider = styled(RawStyledSpacer)`
  contain: layout;
  font-size: 0;
  height: 0;
  overflow: visible;
  padding-left: ${(props) => (props.$isFlush ? undefined : `${smallGutterSize}px`)};
  padding-right: ${(props) => (props.$isFlush ? undefined : `${smallGutterSize}px`)};
  position: relative;
  text-align: center;

  @media (min-width: 720px) {
    padding-left: ${(props) => (props.$isFlush ? undefined : `${largeGutterSize}px`)};
    padding-right: ${(props) => (props.$isFlush ? undefined : `${largeGutterSize}px`)};
  }

  &::before {
    background-color: ${(props) => rgba({ alpha: 0.12, ...getColorRgb(props.$color) })};
    content: '';
    display: inline-block;
    height: 1px;
    max-width: 100%;
    width: ${(props) => (props.$isFullLength ? '100%' : '264px')};
  }
`;

export const StyledDivider = forwardRef(
  (props: StyledDividerProps, ref: mixed) => {
    const { as = 'hr', ...rest } = props;

    return (
      <RawStyledDivider {...rest} ref={ref} as={as} />
    );
  },
);
