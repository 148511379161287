// @flow strict

import React from 'react';
import { StyledSubtitle2Text } from '../../styled-components/Subtitle2/Subtitle2Text';
import type { Subtitle2TextProps } from './Subtitle2TextProps';
import withTypographyText from '../Typography/withTypographyText';

const Subtitle2Text = (props: Subtitle2TextProps) => (
  <StyledSubtitle2Text {...props} />
);

export default withTypographyText(Subtitle2Text);
