// @flow strict

import styled from 'styled-components';

export const RawStyledCanvasModal = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const StyledCanvasModal = RawStyledCanvasModal;
