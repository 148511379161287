// @flow strict

import styled from 'styled-components';
import { StyledTypography } from '../Typography/Typography';

export const RawStyledHeadline3 = styled(StyledTypography)`
  margin-bottom: ${props => (props.$hasSpaceBottom ? '48px' : undefined)};
  margin-top: ${props => (props.$hasSpaceTop ? '48px' : undefined)};
`;

export const StyledHeadline3 = RawStyledHeadline3;
