// @flow strict

import React, { useContext } from 'react';
import Ink from '../Ink/Ink';
import ColorContext from './TabGroupColorContext';
import { StyledTabBodyInk } from '../../styled-components/Tab/TabBodyInk';
import type { TabBodyInkProps } from './TabBodyInkProps';
import { tabBodyInkRippleDuration } from '../../styled-components/Tab/variables';

const TabBodyInk = (props: TabBodyInkProps) => {
  const color = useContext(ColorContext);

  return (
    <StyledTabBodyInk {...props}>
      <Ink color={color} duration={tabBodyInkRippleDuration} />
    </StyledTabBodyInk>
  );
};

export default TabBodyInk;
