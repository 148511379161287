// @flow strict-local

import React, { useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import { StyledMassiveHeroBackgroundBottomImageSvg } from '../../styled-components/MassiveHero/MassiveHeroBackgroundBottomImageSvg';
import Svg from '../../images/borg_bottom.svg';
import type { MassiveHeroBackgroundBottomImageSvgProps } from './MassiveHeroBackgroundBottomImageSvgProps';

const MassiveHeroBackgroundBottomImageSvg = (props: MassiveHeroBackgroundBottomImageSvgProps) => (
  <StyledMassiveHeroBackgroundBottomImageSvg
    {...props}
    $color={useContext(BackgroundColorContext)}
    as={Svg}
  />
);

export default MassiveHeroBackgroundBottomImageSvg;
