// @flow strict-local

import React from 'react';
import { StyledHeroBackgroundImageSvg3 } from '../../styled-components/Hero/HeroBackgroundImageSvg3';

const HeroBackgroundImageSvg3 = () => (
  <StyledHeroBackgroundImageSvg3 />
);

export default HeroBackgroundImageSvg3;
