// @flow strict

import styled from 'styled-components';
import { StyledTypography } from '../Typography/Typography';

const getMaxWidth = (props: StyledTypographyProps) => {
  const { $lineLength } = props;
  if ($lineLength === 'short') return '384px';
  if ($lineLength === 'medium') return '512px';
  return undefined;
};

export const RawStyledHeadline6 = styled(StyledTypography)`
  max-width: ${getMaxWidth};
`;

export const StyledHeadline6 = RawStyledHeadline6;
