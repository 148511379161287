// @flow strict

import React from 'react';
import { StyledIconButtonBodyIcon } from '../../styled-components/IconButton/IconButtonBodyIcon';
import type { IconButtonBodyIconProps } from './IconButtonBodyIconProps';

const IconButtonBodyIcon = (props: IconButtonBodyIconProps) => (
  <StyledIconButtonBodyIcon {...props} />
);

export default IconButtonBodyIcon;
