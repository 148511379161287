// @flow strict

import React, { useCallback, useContext, useRef } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import OutlinedButtonBackgroundColorContext from './OutlinedButtonBackgroundColorContext';
import OutlinedButtonForegroundColorContext from './OutlinedButtonForegroundColorContext';
import { StyledOutlinedButton } from '../../styled-components/OutlinedButton/OutlinedButton';
import { outlinedButtonInkRippleDuration } from '../../styled-components/OutlinedButton/variables';
import type { OutlinedButtonProps } from './OutlinedButtonProps';
import getContrastColor from '../Background/getContrastColor';
import InkPressesContext from '../Ink/InkPressesContext';
import useInkPresses from '../Ink/useInkPresses';

const OutlinedButton = (props: OutlinedButtonProps) => {
  const [inkPresses, handleInkPress] = useInkPresses(outlinedButtonInkRippleDuration);
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const ref = useRef();

  const {
    as = 'button',
    backgroundColor: optionalBackgroundColor,
    foregroundColor: optionalForegroundColor,
    onClick,
    isFullWidth = false,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
  } = props;

  const backgroundColor = optionalBackgroundColor ?? getContrastColor(parentBackgroundColor);
  const foregroundColor = optionalForegroundColor ?? backgroundColor;

  const handleClick = useCallback((e: HTMLEvent) => {
    if (onClick) onClick(e);
    if (e.target) e.target.blur();
    handleInkPress(e, ref.current);
  }, []);

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <InkPressesContext.Provider value={inkPresses}>
        <OutlinedButtonBackgroundColorContext.Provider value={backgroundColor}>
          <OutlinedButtonForegroundColorContext.Provider value={foregroundColor}>
            <StyledOutlinedButton
              ref={ref}
              $backgroundColor={backgroundColor}
              $foregroundColor={foregroundColor}
              $isFullWidth={isFullWidth}
              $spaceBottom={spaceBottom}
              $spaceLeft={spaceLeft}
              $spaceRight={spaceRight}
              $spaceTop={spaceTop}
              as={as}
              onClick={handleClick}
              {...props}
            />
          </OutlinedButtonForegroundColorContext.Provider>
        </OutlinedButtonBackgroundColorContext.Provider>
      </InkPressesContext.Provider>
    </BackgroundColorContext.Provider>
  );
};

export default OutlinedButton;
