// @flow strict

import { rgb } from 'polished';
import styled from 'styled-components';
import { StyledButtonBodyText } from '../Button/ButtonBodyText';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledOutlinedButtonBodyText = styled(StyledButtonBodyText)`
  color: ${props => rgb(getColorRgb(props.$foregroundColor))};
  font-weight: 500;
  margin-top: 11px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const StyledOutlinedButtonBodyText = RawStyledOutlinedButtonBodyText;
