// @flow strict-local

import { rgb } from 'polished';
import { createGlobalStyle } from 'styled-components';
import fontDinotCondensedNormalBoldLatinUrl from '../../fonts/dinot-condensed_normal_bold_latin.woff2';
import fontDinotNormalBoldLatinUrl from '../../fonts/dinot_normal_bold_latin.woff2';
import fontDinotNormalLightLatinUrl from '../../fonts/dinot_normal_light_latin.woff2';
import fontDinotNormalMediumLatinUrl from '../../fonts/dinot_normal_medium_latin.woff2';
import fontDinotNormalRegularLatinUrl from '../../fonts/dinot_normal_regular_latin.woff2';
import fontInconsolataNormalRegularLatinUrl from '../../fonts/inconsolata_normal_regular_latin.woff2';
import { darkPrimaryColorRgb } from '../Palette/variables';

const StyledGlobalReset = createGlobalStyle`
  @font-face {
    font-display: block;
    font-family: Dinot;
    src: url(${fontDinotNormalRegularLatinUrl});
    unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }

  @font-face {
    font-display: block;
    font-family: Dinot;
    font-weight: 300;
    src: url(${fontDinotNormalLightLatinUrl});
    unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }

  @font-face {
    font-display: block;
    font-family: Dinot;
    font-weight: 500;
    src: url(${fontDinotNormalMediumLatinUrl});
    unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }

  @font-face {
    font-display: block;
    font-family: Dinot;
    font-weight: 700;
    src: url(${fontDinotNormalBoldLatinUrl});
    unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }

  @font-face {
    font-display: block;
    font-family: Dinot Condensed;
    font-weight: 700;
    src: url(${fontDinotCondensedNormalBoldLatinUrl});
    unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }

  @font-face {
    font-display: block;
    font-family: Inconsolata;
    src: url(${fontInconsolataNormalRegularLatinUrl});
    unicode-range: u+0000-00ff, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
  }

  body, body *, ::before, ::after {
    animation-fill-mode: forwards;
    background: none;
    border: 0 solid;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    contain: content;
    display: block;
    font-family: Dinot;
    font-size: inherit;
    font-weight: 400;
    line-height: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    pointer-events: none;
    scroll-margin-top: 64px;
    -webkit-tap-highlight-color: transparent;
    text-align: start;
    text-decoration: none;
    text-rendering: geometricPrecision;
    user-select: none;
  }

  svg, svg * {
    color: inherit;
  }

  body, #root { /* stylelint-disable-line selector-max-id */
    contain: size;
  }

  body {
    background-color: ${rgb(darkPrimaryColorRgb)};
  }

`;

export default StyledGlobalReset;
