// @flow strict-local

import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Headline4,
  Headline4Text,
  Hero,
  HeroBackground,
  HeroBackgroundImage,
  HeroBackgroundImageSvg2,
  HeroContent,
  PageContainer,
  Subtitle2,
  Subtitle2Text,
  type HeroProps,
} from '../../../../..';

const PricingPageHero = (props: HeroProps) => (
  <Hero backgroundColor="darkPrimary" {...props}>
    <HeroBackground>
      <HeroBackgroundImage>
        <HeroBackgroundImageSvg2 />
      </HeroBackgroundImage>
    </HeroBackground>
    <HeroContent>
      <PageContainer>
        <Headline4 emphasis="high">
          <Headline4Text>
            <FormattedMessage id="PRICING_PAGE_HERO_TITLE" />
          </Headline4Text>
        </Headline4>
        <Subtitle2 spaceTop="small">
          <Subtitle2Text>
            <FormattedMessage id="PRICING_PAGE_HERO_SUBTITLE" />
          </Subtitle2Text>
        </Subtitle2>
      </PageContainer>
    </HeroContent>
  </Hero>
);

export default PricingPageHero;
