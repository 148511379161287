// @flow strict

import React, { forwardRef } from 'react';
import { StyledMassiveHeroContentFooter } from '../../styled-components/MassiveHero/MassiveHeroContentFooter';
import type { MassiveHeroContentFooterProps } from './MassiveHeroContentFooterProps';

const MassiveHeroContentFooter = (props: MassiveHeroContentFooterProps, ref: mixed) => (
  <StyledMassiveHeroContentFooter {...props} ref={ref} />
);

export default forwardRef(MassiveHeroContentFooter);
