// @flow strict

import React from 'react';
import DeferredContent from './DeferredContent';
import InitialContent from './InitialContent';

const LandingPage = () => (
  <>
    <InitialContent />
    <DeferredContent />
  </>
);

export default LandingPage;
