// @flow strict

import React, { useState } from 'react';
import GlobalDialogOpenContext from './GlobalDialogOpenContext';
import GlobalDialogSetOpenContext from './GlobalDialogSetOpenContext';
import type { GlobalDialogProviderProps } from './GlobalDialogProviderProps';

const GlobalDialogProvider = (props: GlobalDialogProviderProps) => {
  const [open, setOpen] = useState('');
  const { children } = props;

  return (
    <GlobalDialogOpenContext.Provider value={open}>
      <GlobalDialogSetOpenContext.Provider value={setOpen}>
        {children}
      </GlobalDialogSetOpenContext.Provider>
    </GlobalDialogOpenContext.Provider>
  );
};

export default GlobalDialogProvider;
