// @flow strict-local

import React, { useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import { StyledMassiveHeroBackgroundTopImageSvg } from '../../styled-components/MassiveHero/MassiveHeroBackgroundTopImageSvg';
import Svg from '../../images/borg_top.svg';
import type { MassiveHeroBackgroundTopImageSvgProps } from './MassiveHeroBackgroundTopImageSvgProps';

const MassiveHeroBackgroundTopImageSvg = (props: MassiveHeroBackgroundTopImageSvgProps) => (
  <StyledMassiveHeroBackgroundTopImageSvg
    {...props}
    $color={useContext(BackgroundColorContext)}
    as={Svg}
  />
);

export default MassiveHeroBackgroundTopImageSvg;
