// @flow strict

import styled from 'styled-components';
import { StyledTypography } from '../Typography/Typography';

export const RawStyledTitle = styled(StyledTypography)`
  margin-bottom: ${props => (props.$hasSpaceBottom ? '48px' : undefined)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${props => (props.$hasSpaceTop ? '48px' : undefined)};
  max-width: 960px;
  width: 75vw;
`;

export const StyledTitle = RawStyledTitle;
