// @flow strict

import styled from 'styled-components';
import { largeGutterSize, smallGutterSize } from '../Grid/variables';
import { pageContainerSize } from './variables';
import { StyledSpacer } from '../Spacer/Spacer';

export const RawStyledPageContainer = styled(StyledSpacer)`
  contain: none;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => (props.$isFullWidth ? undefined : `${pageContainerSize + (2 * smallGutterSize)}px`)};
  overflow: visible;
  padding-left: ${smallGutterSize}px;
  padding-right: ${smallGutterSize}px;
  width: 100%;

  @media (min-width: 720px) {
    max-width: ${(props) => (props.$isFullWidth ? undefined : `${pageContainerSize + (2 * largeGutterSize)}px`)};
    padding-left: ${largeGutterSize}px;
    padding-right: ${largeGutterSize}px;
  }
`;

export const StyledPageContainer = RawStyledPageContainer;
