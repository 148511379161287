// @flow

import React, { Fragment, lazy, Suspense } from 'react';

const DeferredPipelineSection = lazy(() => import('./Pipeline/index'));
const DeferredUseCasesSection = lazy(() => import('./UseCases/index'));
const DeferredApiIntroductionSection = lazy(() => import('./ApiIntroduction/index'));
const DeferredCodeSamplesSection = lazy(() => import('./CodeSamples/index'));

const DeferredShoppingCartDemoSection = process.env.ENV === 'development'
  ? lazy(() => import('./ShoppingCartDemo/ShoppingCartDemoSection'))
  : Fragment;

const LandingPageDeferredContent = () => (
  <Suspense fallback={Fragment}>
    <DeferredPipelineSection />
    <Suspense fallback={Fragment}>
      <DeferredUseCasesSection />
      <Suspense fallback={Fragment}>
        <DeferredApiIntroductionSection />
        <Suspense fallback={Fragment}>
          <DeferredCodeSamplesSection />
          <Suspense fallback={Fragment}>
            <DeferredShoppingCartDemoSection />
          </Suspense>
        </Suspense>
      </Suspense>
    </Suspense>
  </Suspense>
);

export default LandingPageDeferredContent;
