// @flow strict-local

import { rgb } from 'polished';
import styled from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';

export const RawStyledToolbarBody = styled.div`
  align-items: center;
  background-color: ${props => (props.$backgroundColor ? rgb(getColorRgb(props.$backgroundColor)) : undefined)};
  height: 100%;
  width: 100%;
`;

export const StyledToolbarBody = RawStyledToolbarBody;
