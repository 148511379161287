// @flow strict-local

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledBody1Text = styled(StyledTypographyText)`
  font-size: 18px;
  line-height: 36px;
  margin: -9px 0;
`;

export const StyledBody1Text = RawStyledBody1Text;
