// @flow strict

import SpeechRecognitionIsSupportedProvider from './SpeechRecognitionIsSupportedProvider';
import SpeechRecognitionIsUnsupportedProvider from './SpeechRecognitionIsUnsupportedProvider';
import isSpeechRecognitionSupported from './isSpeechRecognitionSupported';

const SpeechRecognitionProvider = isSpeechRecognitionSupported()
  ? SpeechRecognitionIsSupportedProvider
  : SpeechRecognitionIsUnsupportedProvider;

export default SpeechRecognitionProvider;
