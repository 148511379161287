// @flow strict-local

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledSubtitle2Text = styled(StyledTypographyText)`
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  margin: -4px 0;
`;

export const StyledSubtitle2Text = RawStyledSubtitle2Text;
