// @flow strict

import React from 'react';
import Svg from '@mdi/svg/svg/menu.svg';
import { StyledSvg } from '../../styled-components/Svg/Svg';
import type { SvgProps } from './SvgProps';

const MenuSvg = (props: SvgProps) => {
  const { as = Svg, ...rest } = props;

  return (
    <StyledSvg {...rest} as={as} />
  );
};

export default MenuSvg;
