// @flow strict

import styled from 'styled-components';

export const RawStyledMassiveHeroBackgroundBottomImage = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledMassiveHeroBackgroundBottomImage = RawStyledMassiveHeroBackgroundBottomImage;
