// @flow strict-local

import { css } from 'styled-components';
import { heroDesktopHeight, heroMobileHeight } from './variables';
import { toolbarExtendedHeight, toolbarHeight } from '../Toolbar/variables';
import type { StyledHeroProps } from './HeroProps';

export default (props: StyledHeroProps) => {
  let offsetSize;

  switch (props.$offsetBy) {
    case 'toolbar':
      offsetSize = toolbarHeight;
      break;
    case 'toolbarExtended':
      offsetSize = toolbarExtendedHeight;
      break;
    default:
      offsetSize = 0;
      break;
  }

  return css`
    height: ${heroMobileHeight + offsetSize}px;
    margin-top: -${offsetSize}px;
    position: relative;

    @media (min-width: 720px) {
      height: ${heroDesktopHeight + offsetSize}px;
    }
  `;
};
