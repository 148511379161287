// @flow strict

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledHeadline3Text = styled(StyledTypographyText)`
  font-size: 48px;
  font-weight: 300;
  line-height: 52px;
  margin: -2px 0;
`;

export const StyledHeadline3Text = RawStyledHeadline3Text;
