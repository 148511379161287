// @flow strict

import styled from 'styled-components';

export const RawStyledInk = styled.div`
  left: 0;
  overflow: hidden;
  padding-top: 150%;
  position: absolute;
  top: 0;
  width: 150%;
`;

export const StyledInk = RawStyledInk;
