// @flow strict-local

import styled from 'styled-components';
import getSharedCss from './getSharedCss';

export const RawStyledHeroGroup = styled.div`
  ${getSharedCss}
  position: relative;
`;

export const StyledHeroGroup = RawStyledHeroGroup;
