// @flow strict

import React, { useContext, useEffect, useRef } from 'react';
import TabOnChangeBodyContentDimContext from './TabOnChangeBodyContentDimContext';
import { StyledTabBodyContent } from '../../styled-components/Tab/TabBodyContent';
import type { TabBodyContentProps } from './TabBodyContentProps';

const TabBodyContent = (props: TabBodyContentProps) => {
  const handleChangeDim = useContext(TabOnChangeBodyContentDimContext);
  const ref = useRef();

  useEffect(() => {
    const el = ref.current;

    if (el && handleChangeDim) {
      const { width, x } = el.getBoundingClientRect();
      handleChangeDim(width, x);
    }
  });

  return (
    <StyledTabBodyContent ref={ref} {...props} />
  );
};

export default TabBodyContent;
