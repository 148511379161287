// @flow strict

import type { Color } from './Color';
import type { Emphasis } from './Emphasis';
import getColorRgb from './getColorRgb';
import getEmphasisOpacity from './getEmphasisOpacity';

export default (color: Color, emphasis: Emphasis) => {
  const rgb = getColorRgb(color);
  const alpha = getEmphasisOpacity(emphasis);
  const rgba = { ...rgb, alpha };
  return rgba;
};
