// @flow strict

import styled, { css, keyframes } from 'styled-components';
import { acceleratedTimingFunction, deceleratedTimingFunction } from '../Animation/variables';
import type { StyledMassiveHeroBackgroundProps } from './MassiveHeroBackgroundProps';
import { massiveHeroEnterAnimationDuration, massiveHeroExitAnimationDuration } from './variables';

const animateEnterName = keyframes`
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const animateExitName = keyframes`
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const getAnimation = (props: StyledMassiveHeroBackgroundProps) => {
  const { $transitionState } = props;

  switch ($transitionState) {
    case 'entering': {
      return css`
        animation-duration: ${massiveHeroEnterAnimationDuration}ms;
        animation-name: ${animateEnterName};
        animation-timing-function: ${deceleratedTimingFunction};
      `;
    }
    case 'exiting': {
      return css`
        animation-duration: ${massiveHeroExitAnimationDuration}ms;
        animation-name: ${animateExitName};
        animation-timing-function: ${acceleratedTimingFunction};
      `;
    }
    default:
      return undefined;
  }
};

export const RawStyledMassiveHeroContent = styled.div`
  ${getAnimation}
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const StyledMassiveHeroContent = RawStyledMassiveHeroContent;
