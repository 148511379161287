// @flow strict

import React from 'react';
import { StyledHeadline3 } from '../../styled-components/Headline3/Headline3';
import type { Headline3Props } from './Headline3Props';
import withTypography from '../Typography/withTypography';

const Headline3 = (props: Headline3Props) => (
  <StyledHeadline3 {...props} />
);

export default withTypography(Headline3);
