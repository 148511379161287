// @flow strict

import styled from 'styled-components';

export const RawStyledTabBodyInk = styled.div`
  bottom: -6px;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -6px;
`;

export const StyledTabBodyInk = RawStyledTabBodyInk;
