// @flow strict

import { rgb } from 'polished';
import styled, { keyframes } from 'styled-components';
import getColorRgb from '../Palette/getColorRgb';
import { acceleratedTimingFunction } from '../Animation/variables';

const animateInName = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  25% {
    opacity: 0.34;
  }

  100% {
    opacity: 0;
    transform: scale(1) translate(-50%, -50%);
  }
`;

export const RawStyledInkRipple = styled.div`
  animation-duration: ${props => props.$duration}ms;
  animation-name: ${animateInName};
  animation-timing-function: ${acceleratedTimingFunction};
  background-color: ${props => rgb(getColorRgb(props.$color))};
  border-radius: 50%;
  content: '';
  height: 100%;
  left: ${props => props.$x}px;
  opacity: 0;
  position: absolute;
  top: ${props => props.$y}px;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  width: 100%;
`;

export const StyledInkRipple = RawStyledInkRipple;
