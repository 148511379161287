// @flow strict

import styled from 'styled-components';
import { StyledSpacer } from '../Spacer/Spacer';

export const RawStyledButton = styled(StyledSpacer)`
  cursor: pointer;
  display: inline-block;
  height: 48px;
  pointer-events: auto;
  position: relative;
  width: ${props => (props.$isFullWidth ? '100%' : undefined)};
`;

export const StyledButton = RawStyledButton;
