// @flow strict

import React, { cloneElement, forwardRef } from 'react';
import { Transition } from '@material-ui/react-transition-group';
import TransitionStateContext from './TransitionStateContext';
import type { TransitionProps } from './TransitionProps';

const ExtendedTransition = (props: TransitionProps, ref) => {
  const { children, ...rest } = props;

  return (
    <Transition mountOnEnter unmountOnExit {...rest}>
      {state => (
        <TransitionStateContext.Provider value={state}>
          {
            typeof children === 'function'
              ? children(state)
              : cloneElement(children, { ref })
          }
        </TransitionStateContext.Provider>
      )}
    </Transition>
  );
};

export default forwardRef(ExtendedTransition);
