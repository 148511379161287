// @flow strict

import styled from 'styled-components';
import { StyledTypographyText } from '../Typography/TypographyText';

export const RawStyledHeadline6Text = styled(StyledTypographyText)`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: -6px 0;
`;

export const StyledHeadline6Text = RawStyledHeadline6Text;
