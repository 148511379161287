// @flow strict-local

import styled from 'styled-components';

export const RawStyledMassiveHeroBackgroundTopImage = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: bottom;
`;

export const StyledMassiveHeroBackgroundTopImage = RawStyledMassiveHeroBackgroundTopImage;
