// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import { StyledHeroBackgroundImage } from '../../styled-components/Hero/HeroBackgroundImage';
import HeroTransitionDirectionContext from './HeroTransitionDirectionContext';
import TransitionStateContext from '../Transition/TransitionStateContext';
import type { HeroBackgroundImageProps } from './HeroBackgroundImageProps';

const HeroBackgroundImage = (props: HeroBackgroundImageProps, ref: mixed) => {
  const transitionDirection = useContext(HeroTransitionDirectionContext);
  const transitionState = useContext(TransitionStateContext);

  return (
    <StyledHeroBackgroundImage
      {...props}
      ref={ref}
      $transitionDirection={transitionDirection}
      $transitionState={transitionState}
    />
  );
};

export default forwardRef(HeroBackgroundImage);
