// @flow strict-local

import React, { forwardRef, useContext } from 'react';
import BackgroundColorContext from '../Background/BackgroundColorContext';
import getContrastColor from '../Background/getContrastColor';
import { StyledDivider } from '../../styled-components/Divider/Divider';
import type { DividerProps } from './DividerProps';

const Divider = (props: DividerProps, ref: mixed) => {
  const parentBackgroundColor = useContext(BackgroundColorContext);
  const contrastColor = getContrastColor(parentBackgroundColor);
  const defaultColor = contrastColor;
  const defaultIsFlush = false;

  const {
    color = defaultColor,
    isFlush = defaultIsFlush,
    isFullLength,
    spaceBottom,
    spaceLeft,
    spaceRight,
    spaceTop,
    ...rest
  } = props;

  return (
    <StyledDivider
      {...rest}
      ref={ref}
      $color={color}
      $isFlush={isFlush}
      $isFullLength={isFullLength}
      $spaceBottom={spaceBottom}
      $spaceLeft={spaceLeft}
      $spaceRight={spaceRight}
      $spaceTop={spaceTop}
    />
  );
};

export default forwardRef(Divider);
