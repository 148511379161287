// @flow strict

import styled from 'styled-components';

export const RawStyledButtonBodyText = styled.span`
  display: inline-block;
  font-size: ${props => (props.$size === 'small' ? 11 : 12)}px;
  font-weight: ${props => (props.$size === 'small' ? 400 : 500)};
  letter-spacing: 2.4px;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const StyledButtonBodyText = RawStyledButtonBodyText;
