// @flow strict-local

import React, { Fragment, lazy, Suspense } from 'react';

const DeferredRootDrawer = lazy(() => import('./RootDrawer'));

const AsyncRootDrawer = () => (
  <Suspense fallback={Fragment}>
    <DeferredRootDrawer />
  </Suspense>
);

export default AsyncRootDrawer;
