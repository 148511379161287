// @flow strict

import styled from 'styled-components';
import { RawStyledTypography } from '../Typography/Typography';

export const RawStyledSubtitle1 = styled(RawStyledTypography)`
  margin-bottom: ${(props) => (props.$hasSpaceBottom ? '16px' : undefined)};
  margin-top: ${(props) => (props.$hasSpaceTop ? '16px' : undefined)};
`;

export const StyledSubtitle1 = RawStyledSubtitle1;
