// @flow strict

import styled from 'styled-components';

export const RawStyledTabGroupSelector = styled.div`
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 6px;
`;

export const StyledTabGroupSelector = RawStyledTabGroupSelector;
